<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Mala Arkana: Palice
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/tarot">Tarot</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Mala Arkana: Palice</li>
            </ol>
        </nav>
    </div>
</div>
<div class="container-fluid p-0 bg-header">
    <div class="container p-md-4 p-2">

        <div class="p-md-2 text-black">

            <div class="py-4 text-center">
                <h1 class="text-center">Mala Arkana: Palice</h1>
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>

            <p v-html="opis"></p>

            <div class="py-4 text-center">
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>

            <strong>Karte palic so:</strong> <span v-for="karte in palice">
                <router-link class="text-decoration-none text-white" :to="`${karte.to}`">{{ karte.ime }}</router-link> |
            </span>

            <div class="py-4 text-center">
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>

            <div class="row row-cols-1 row-cols-xl-4 g-4">
                <div class="col-xl d-flex align-items-center justify-content-center" v-for="(arkana, index) in palice"
                     :key="index">
                    <router-link :to="`${arkana.to}`" class="text-decoration-none">
                        <div class="card-my">
                            <div class="content-my">
                                <div class="front">
                                    <img :src="arkana.img" class="img-fluid shadow" :alt="arkana.ime">
                                </div>
                                <div class="back p-2 text-white bg-dark shadow bg-image-cards bg-opacity-75">
                                    <h3 class="text-shadow">{{ arkana.ime }}</h3>
                                    <p>{{ arkana.pregled }}</p>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
