export default {
    data() {
        return {
            naslov: "Sadhana XII – Obešenec",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            meditacijaManpiura:
                '<p>Pričnite se zavedati, da sedite na nazaj obrnjenem trikotniku svetlo rdeče barve. Trikotnik ima ravno tolikšen premer, da lahko na njem sedite. Okoli trikotnika je krog, ki ima 10 lotosovih cvetov, ki so modre barve. Počutite se polni ravno pravšnje energije, ki vas napaja. Ste pomirjeni in prizemljeni. Medtem ko sedite čutite vse smeri neba vendar tej prostranosti okoli vas ni konca. Je brezmejni prostor. Pomembno je, da to čutite.</p>' +
                '<p>Po nekaj trenutkih pričnite vizualizirati, kako sedite na lotosu z 10imi modrimi cvetovi v katerem je svetlo rdeč trikotnik. Kmalu se pod vami pojavi še ogenj, ki izgoreva vse temno v vas in okoli vas.</p>' +
                '<p>Sedaj vidite celotno sliko čakre v predelu swadhisthane in ponovite naslednje mantre še enkrat: <b>DAM</b> (in vizualizirajte prvi cvet čakre kako še bolj sveti in pojdite naprej v smeri urinega kazalca), <b>DHAM</b>, <b>NAM</b>, <b>TAM</b>, <b>THAM</b>, <b>DAM</b>, <b>DHAM</b>, <b>NAM</b>, <b>PAM</b> in <b>PHAM</b>. Nato ponovite <b>RAM</b> 108x in čutite, kako vse slabo izgoreva iz vas in na koncu ostane le čista svetloba.</p>',

            viparetmudra: '<p>Lezite na hrbet. Roke so ravne, ob telesu. Z vdihom pokrčimo kolena in dvignemo noge in zadnjico. Roke dvignite pod boke, da podprete zadnjico. Komolci ostanejo na tleh. Noge poravnajte navpično navzgor. Sprostite mišice stopal, nog in bokov. Dihajte normalno, ostanite v tem položaju, dokler vam je udobno in z vdihom čutite, kako vroč nektar teče iz manipura čakre po hrbtenici do vishuddhi čakre kjer se zbere in zadržite dih za nekaj sekund in čutite, kako se netar ohladi z izdihom čutite kako se nektar pretaka iz te čakre skozi ajna čakro, skozi bindu in v sahasraro. Nato se vrnete z zavedanjem nazaj v manipura čakro in ponovite postopek. To ponovite, dokler vam je udobno. Prvič morda 1x in povečujete, da v nekaj mesecih lahko naredite 11 ponovitev ali več. Nato vdihnite in z izdihom pokrčimo kolena proti čelu, počasi spustimo zadnjico in noge ter se vrnemo v začetni položaj. To vajo naredite samo enkrat. Tisti z visokim pritiskom naj tega položaja ne delajo.</p>',

            kapalabhati: '<p>Ko ste pripravljeni začeti vaditi kapalabhati, močno izdihnite skozi nosnice (brez naprezanja ali napetosti) in istočasno dinamično povlecite popek navznoter proti hrbtenici ter nežno skrčite trebušne mišice. Ko sprostite trebuh, pustite, da vdih poteka pasivno; pljuča se bodo napolnila brez napora. Takoj začnite s ponovnim močnim izdihom, popek spet potegnite navznoter, nato pa pustite, da vdih sledi pasivno. Ta postopek se ponavlja v hitrem zaporedju - en izdih na sekundo ali hitreje. Začnite z dvajsetimi ponovitvami. Ob zadnjem izdihu vdihnite globoko tako da najprej napolnite trebuh nato pljuča in zadržite dih za nekaj sekund in nekajkrat ponovite mantro <b>OM</b>. Z vadbo bodo trebušne mišice postale močnejše in lahko boste počasi dosegli petdeset do sto ponovitev naenkrat.</p>',

            meditacijaObesenec: '<p>Predstavljajte si sebe, kako visite navzdol obrnjeni med svetlobo oziroma nebesi zgoraj in med zemljo spodaj. Nato si recite: "Na križu, ki visi med nebom in zemljo, visim v popolnem ravnotežju. Z žrtvovanjem vsega, kar sem bil, bom podedoval stanje svojega prihodnjega bitja. Čeprav je moja glava v nižjih svetovih, je moje oko usmerjeno v svetila nebes. Sem drevo življenja, ukoreninjeno v božansko zemljo. Moje korenine segajo v Božje srce in moja krona je na zemlji. Jaz sem očitna slava, obešena med skrajnostmi visokega in nizkega, in bom uravnotežen, miren in zmagovalec sredi žrtvovanja in preobrata. Ti z malo modrosti me bodo imeli za neumnega, ki ne hodi po njihovih poteh, jaz pa poznam skrite načrte tistega, ki me je poslal. Lebdim na vodah velikega morja in varen sem pred nevihto. V meni se bo izpolnila skrivnost, ki visi v kozmosu."</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

            dodatno: '<ol><li>Pričnite raziskovati rune, če želite.</li></ol>',



        }
    }
}
