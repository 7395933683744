<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Qliphot
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Qliphot</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot">
    <div class="container text-center p-3">
        <div class="py-4 text-center">
            <h1>Qliphot</h1>
            <div class="lead text-center">{{ qliphothRek }}</div>
            <img src="~@/assets/hrline.png" class="img-fluid">
        </div>

    </div>
    <div class="container pb-3">
        <div class="text-center d-md-none">
            <img src="~@/assets/kliphot/kliphot.png" alt="Kliphot" class="img-fluid">
        </div>
        <img src="~@/assets/kliphot/kliphot.png" alt="Kliphot" class="img-fluid float-end d-none d-md-block">
        <span v-html="qliphothOpis"></span>

        <div class="py-4 text-center">
            <h1>Literatura</h1>
            <img src="~@/assets/hrline.png" class="img-fluid">
        </div>

        <div class="row row-cols-1 row-cols-lg-2 g-4">

            <div class="col" v-for="(literatura, index) in knjige" :key="index">
                <div class="card bg-image-qlipha bg-opacity-75 text-white bg-dark rounded-0 shadow-card mb-3 position-relative">
                    <a :href="literatura.depository" target="_blank">
                        <img src="~@/assets/buy.png" class="position-absolute bottom-0 end-0 p-2">
                    </a>
                    <div class="row g-0">
                        <div class="col-lg-4">
                            <img :src="literatura.image" class="img-fluid h-100" style="min-width: 100%"
                                 :alt="literatura.naslovKnjige">
                        </div>
                        <div class="col-lg-8">
                            <div class="card-body">
                                <h4 class="card-title">{{ literatura.naslovKnjige.toUpperCase() }}</h4>
                                <h6 class="card-title fst-italic">{{ literatura.podnaslov }}</h6>
                                <p class="card-text">{{ literatura.opis }}</p>
                                <p class="card-text"><small class="text-muted">Avtor: {{ literatura.avtor }}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
