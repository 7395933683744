<div class="container-fluid text-white bg-tarot-index">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Tarot postavitve
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Tarot postavitve</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot rounded-0 pb-3">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>Postavitev tarot kart</h1>
            <p class="lead">Pred mešanjem kart si vedno vzemite čas, da se sprostite in postavite vprašanje. Med
                mešanjem se osredotočite na vprašanje in ga resnično začutite. Ko boste dobili občutek, da so karte
                dovolj pomešane in, da bodo odgovorile, jih pričnite obračati.</p>
            <div class=" d-none d-md-block">
                <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
            </div>
        </div>

        <div class="row row-cols-1 row-cols-xl-3 g-4">
            <!-- 1 karta -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 border-dark border-1 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">1 karta</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
                    <div class="card-body">
                        <p class="card-text">Pri branju z eno karto je odličen način ta, da spoznamo pomen karte in se z
                            njo povežemo, hkrati pa
                            si lahko vzamemo čas, da karto do cela spoznamo. Ko uporabite eno karto pri branju, si
                            preden jo izberete, postavite vprašanje, kot je: "Kakšno sporočilo imate zame za današnji
                            dan?". Z eno karto si lahko odgovorite na vprašanja, ki od kart zahtevajo odgovore kot so
                            "Da" ali "Ne" ali pa "Morda". Vsekakor je ta odgovor zelo površinski in je primeren za hitre
                            odločitve.</p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot rounded-0 fs-5 fw-bold"
                            @click="showModal1 = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 3 karte -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 border-dark border-1 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">3 karte</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
                    <div class="card-body">
                        <p class="card-text">Ta postavitev kart je odlična za manjše branje in pa za bolj natačni
                            odgovor pri vprašanjih, ki zahtevajo odgovor "Da" ali "Ne". Če je odgovor 3x "Da" potem je
                            odločen "Da!". Sicer pa prva karta predstavlja vašo
                            preteklost, druga vašo sedanjost in tretja vašo prihodnost (kakšen bi lahko bil izid). To
                            postavitev lahko uporabite tudi za branje drugih aspektov v življenju kot je na primer 1.
                            um, 2. telo, 3. duša ali pa 1. ljubezen, 2. denar, 3. dom ali 1. situacija, 2. ovire, 3.
                            nasvet.</p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModal2 = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- Keltski križ -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">Keltski križ</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
                    <div class="card-body">
                        <p class="card-text">Karta 1 predstavlja sedanjost, kaj se trenutno dogaja. Karta 2 predstavlja
                            izziv ali težavo s katero se trenutno soočate. Karta 2 predstavlja preteklost oziroma
                            dogodke, ki so vas privedli do trenutne situacije. Karta 4 prihodnost oziroma, kaj se lahko
                            naredi v naslednjih dneh ali tednih. Karta 5 odraža vaš cilj, željo ali najboljši izzid
                            glede na situacijo. Karta 6 lahko prinese sporočilo iz notranjosti, podzavesti. Karta 7 je
                            nasvet, kakšen pristop je potreben, da se stvari uredijo. Karta 8 je karta zunanjih vplivov
                            ljudi, energij ali dogodkov. Karta 9 je karta upanja in/ali strahov. In karta 10 je karta
                            rezultata oziroma kam vodi situacija in kako težavo rešiti.</p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModal4 = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- Pretekla življenja -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">Pretekla življenja</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
                    <div class="card-body">
                        <p class="card-text">Karta 1 predstavlja, kakšen je vpliv le teh na vaša čustva, karta 2
                            predstavlja kakšen je vpliv na vaše duševno stanje, karta 3 kakšen je vpliv na vaše duhovno
                            stanje, karta 4 kakšen je vpliv na vaše fizično stanje, karta 5 prestavlja vaš karmični dolg
                            in karta 6 kakšne so vaše karmične lekcije, ki se jih morate naučiti. Sledi karta 7, ki
                            predstavlja vpliv preteklega življenja na sedanje življenje in karta 8 predstavlja vpliv
                            preteklega življenja na vašo prihodnost.</p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModal5 = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- Mandala -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">Mandala</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
                    <div class="card-body">
                        <p class="card-text">Karta 1 predstavlja vas. Karta 2 predstavlja vaše ambicije, želje in
                            primarne pozive. Karta 3 predstavlja Ideale, cilje in pot do duhovnega zadovoljstva. Karta 4
                            predstavlja resnične dosežke in življenjsko pot. Karta 5 predstavlja odvisnosti,
                            zasvojenosti in zmotne vrednote. Karta 6 predstavlja prednosti in pozitivne osebnostne
                            lastnosti. Karta 7 predstavlja napake in slabosti. Karta 8 govori o vašem samozavedanju in
                            samopodobi in karta 9 o vaših željah in višjih namenih.</p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModal7 = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- Vaše rojstvo -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">Vaše rojstvo</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
                    <div class="card-body">
                        <p class="card-text">Karta 1 predstavlja vaše trenutno mesto v svetu. Karta dva predstavlja vaše
                            cilje za naslednje leto. Karta 3 govori o tem, kaj je tisto, kar vam daje moč. Karta 4
                            govori o tem, katere so notranje moči, ki jih morate razviti. Karta 5 govori o prisotnem
                            fizičnem in materialnem počutju. Karta 6 govori o trenutnem čustvenem stanju. Karta 7
                            predstavlja vaše trenutno duhovno stanje. Karta 8 predstavlja nasprotovanja in karta 9
                            dejanja, ki so potrebna za doseganje ciljev.</p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModal8 = true">
                        Obrni
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal ENE KARTE: showModal1 -->
        <vue-final-modal v-model="showModal1" classes="modal-container animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModal1 = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Naključno izbrana karta</span>
            <div class="modal__content d-flex align-items-center justify-content-center">
                <div>
                    <router-link :to="selectedImage.to" class="text-decoration-none">
                        <img :src="selectedImage.src" :alt="selectedImage.name" class="img-fluid"/>
                    </router-link>
                    <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                        {{ selectedImage.name }}</h6>
                </div>
            </div>
        </vue-final-modal>

        <!-- Modal TREH KART: showModal2 -->
        <vue-final-modal v-model="showModal2" classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModal2 = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">3 naključne karte</span>

            <div class="modal__content">
                <div class="container">
                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3" v-for="(image, index) in randomImageUrls" :key="image.src">
                            <h6 class="text-center bg-dark bg-gradient text-white m-0 p-1 fw-bold">{{ index + 1 }}</h6>
                            <router-link :to="image.to" class="text-decoration-none">
                                <img :src="image.src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ image.name }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>

        <!-- Modal KELTSKI KRIŽ KART: showModal4 -->
        <vue-final-modal v-model="showModal4" classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModal4 = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Naključno izbrane karte v križu</span>

            <div class="modal__content">

                <div class="container d-none d-sm-block">
                    <!-- VIDNO SAMO NAD LG-->
                    <div class="row">
                        <!-- Leva stran KRIŽ -->
                        <div class="col-sm-8">
                            <div class="container  text-center">

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-4">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[2].name }}</h6>
                                        <router-link :to="randomImageUrlsCeltic[2].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[2].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            3</h6>
                                    </div>
                                    <div class="col-sm-4"></div>
                                </div>

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-sm-4">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[5].name }}</h6>
                                        <router-link :to="randomImageUrlsCeltic[5].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[5].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            6</h6>
                                    </div>

                                    <div class="col-sm-3 position-relative">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            1</h6>
                                        <router-link :to="randomImageUrlsCeltic[0].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[0].src"
                                                 class="img-fluid position-absolute top-50 start-0 translate-middle-y"
                                                 style=" z-index: 1;"/>
                                        </router-link>

                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            2</h6>
                                        <router-link :to="randomImageUrlsCeltic[1].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[1].src"
                                                 class="img-fluid position-absolute top-0 start-0 rotate"
                                                 style=" z-index: 2;"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[1].name }}</h6>
                                    </div>

                                    <div class="col-sm-4">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[4].name }}</h6>
                                        <router-link :to="randomImageUrlsCeltic[4].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[4].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            5</h6>
                                    </div>
                                </div>

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-sm-4"></div>
                                    <div class="col-sm-4">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[3].name }}</h6>
                                        <router-link :to="randomImageUrlsCeltic[3].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[3].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            4</h6>
                                    </div>
                                    <div class="col-sm-4">
                                        <h6 class="text-center fw-bold">Dve karti na sredini:</h6>
                                        <h6 class="text-center fw-bold">Karta zadaj 1:<br>
                                            {{ randomImageUrlsCeltic[0].name }}</h6>
                                        <h6 class="text-center fw-bold">Karta spredaj 2:<br>
                                            {{ randomImageUrlsCeltic[1].name }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Desna stran KRIŽ -->
                        <div class="col-sm-4">
                            <div class="container text-center">

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-8 pb-1">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            10</h6>
                                        <router-link :to="randomImageUrlsCeltic[9].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[9].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[9].name }}</h6>
                                    </div>
                                </div>

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-8 pb-1">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            9</h6>
                                        <router-link :to="randomImageUrlsCeltic[8].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[8].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[8].name }}</h6>
                                    </div>
                                </div>

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-8 pb-1">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            8</h6>
                                        <router-link :to="randomImageUrlsCeltic[7].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[7].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[7].name }}</h6>
                                    </div>
                                </div>

                                <div class="row d-flex align-items-center justify-content-center">
                                    <div class="col-8">
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            7</h6>
                                        <router-link :to="randomImageUrlsCeltic[6].to" class="text-decoration-none">
                                            <img :src="randomImageUrlsCeltic[6].src" class="img-fluid"/>
                                        </router-link>
                                        <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                            {{ randomImageUrlsCeltic[6].name }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- VIDNO SAMO POD LG-->
                <div class="d-sm-none text-center">
                    Za ogled te postavitve morate imeti napravo z širino ekrana, ki je večja od 575px.
                </div>

            </div>
        </vue-final-modal>

        <!-- Modal PRETEKLA ŽIVLJENJA: showModal5 -->
        <vue-final-modal v-model="showModal5" classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModal5 = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Pretekla življenja</span>

            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsPastLife[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[0].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsPastLife[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[1].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsPastLife[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[2].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsPastLife[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsPastLife[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[4].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsPastLife[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[5].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsPastLife[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[6].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">8</h6>
                            <router-link :to="randomImageUrlsPastLife[7].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPastLife[7].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPastLife[7].name }}</h6>
                        </div>

                    </div>


                </div>
            </div>
        </vue-final-modal>

        <!-- Modal MANDALA: showModal7 -->
        <vue-final-modal v-model="showModal7" classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModal7 = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Mandala</span>

            <div class="modal__content">
                <div class="container text-center">
                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">9</h6>
                            <router-link :to="randomImageUrlsMandala[8].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[8].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[8].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">8</h6>
                            <router-link :to="randomImageUrlsMandala[7].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[7].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[7].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsMandala[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[1].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsMandala[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[6].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsMandala[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[0].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsMandala[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsMandala[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[5].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsMandala[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsMandala[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMandala[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMandala[4].name }}</h6>
                        </div>
                    </div>


                </div>
            </div>
        </vue-final-modal>

        <!-- Modal VAŠE ROJSTVO: showModal8 -->
        <vue-final-modal v-model="showModal8" classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModal8 = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Vaše rojstvo</span>

            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsBirthday[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[0].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsBirthday[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[1].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsBirthday[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[2].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsBirthday[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsBirthday[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[4].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsBirthday[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[5].name }}</h6>
                        </div>

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsBirthday[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[6].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">8</h6>
                            <router-link :to="randomImageUrlsBirthday[7].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[7].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[7].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">9</h6>
                            <router-link :to="randomImageUrlsBirthday[8].to" class="text-decoration-none">
                                <img :src="randomImageUrlsBirthday[8].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsBirthday[8].name }}</h6>
                        </div>

                    </div>


                </div>
            </div>
        </vue-final-modal>

    </div>

    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>25 postavitev za osvetlitev poti</h1>
            <p class="lead">Sledi 25 postavitev, ki utelešajo vsako od 22-ih kart v veliki arkani.</p>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>
        <div v-html="opis25postavitev"></div>
        <div class="pb-4 text-center">
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>
        <!-- 25 Cards -->
        <div class="row row-cols-1 row-cols-xl-1 g-4">
            <!-- 0 - Norec -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent">Norec: Poglej preden skočiš</h3>
                    <div class="card-body">
                        <div v-html="opisFool"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalFool = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 1 - Magik -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Magik: Spoznajte svojo moč; Uporabite svoja
                        orodja</h4>
                    <div class="card-body">
                        <div v-html="opisMagician"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalMagician = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 2 - Visoka Svečenica 1 -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Visoka Svečenica: Skrivni svet sanj</h4>
                    <div class="card-body">
                        <div v-html="opisPriestessOne"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalPriestessOne = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 2 - Visoka Svečenica 2 -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Visoka Svečenica: Intuitivni vpogled</h4>
                    <div class="card-body">
                        <div v-html="opisPriestessTwo"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalPriestessTwo = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 3 - Cesarica -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Cesarica: Nega se začne pri sebi</h4>
                    <div class="card-body">
                        <div v-html="opisEmpress"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalEmpress = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 4 - Cesar -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Cesar: Zmagoviti načrt</h4>
                    <div class="card-body">
                        <div v-html="opisEmperor"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalEmperor = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 5 - Svečenik -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Svečenik: Iskanje sveta modreca</h4>
                    <div class="card-body">
                        <div v-html="opisHierophant"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalHierophant = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 6 - Ljubimca 1 -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Ljubimca: Za pare</h4>
                    <div class="card-body">
                        <div v-html="opisLoversOne"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalLoversOne = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 6 - Ljubimca 2-->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Ljubimca: Za samske</h4>
                    <div class="card-body">
                        <div v-html="opisLoversTwo"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalLoversTwo = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 7 - Kočija -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Kočija: Neomajni razgled</h4>
                    <div class="card-body">
                        <div v-html="opisChariot"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalChariot = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 8 - Moč -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Moč: Zmaga nad strahom</h4>
                    <div class="card-body">
                        <div v-html="opisStrength"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalStrength = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 9 - Puščavnik -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Puščavnik: Pojdi vase</h4>
                    <div class="card-body">
                        <div v-html="opisHermit"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalHermit = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 10 - Kolo sreče -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Kolo sreče: Karmične lekcije</h4>
                    <div class="card-body">
                        <div v-html="opisWheel"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalWheel = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 11 - Pravica -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Pravica: Resnica v ravnotežju</h4>
                    <div class="card-body">
                        <div v-html="opisJustice"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalJustice = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 12 - Obešenec -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Obešenec: Resnica v perspektivi</h4>
                    <div class="card-body">
                        <div v-html="opisHanged"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalHanged = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 15 - Smrt -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Smrt: Sprejemanje sprememb</h4>
                    <div class="card-body">
                        <div v-html="opisDeath"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalDeath = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 16 - Zmernost -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Zmernost: Sveta poravnava</h4>
                    <div class="card-body">
                        <div v-html="opisTemperance"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalTemperance = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 17 - Hudič -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Hudič: Zdrave meje</h4>
                    <div class="card-body">
                        <div v-html="opisDevil"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalDevil = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 18 - Trdnjava -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Trdnjava: Onkraj ruševin</h4>
                    <div class="card-body">
                        <div v-html="opisTower"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalTower = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 19 - Zvezda -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Zvezda: Luč, ki vodi</h4>
                    <div class="card-body">
                        <div v-html="opisStar"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalStar = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 20 - Luna -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Luna: Sence v središču</h4>
                    <div class="card-body">
                        <div v-html="opisMoon"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalMoon = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 21 - Sonce -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Sonce: Najboljši obraz naprej</h4>
                    <div class="card-body">
                        <div v-html="opisSun"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalSun = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 22 - Sodba -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Sodba: Upoštevajte klic</h4>
                    <div class="card-body">
                        <div v-html="opisJudgement"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalJudgement = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 23 - Svet -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Svet: Prihaja poln krog</h4>
                    <div class="card-body">
                        <div v-html="opisWorld"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalWorld = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- 24 - 22 Kart -->
            <div class="col">
                <div class="card bg-dark border-dark border-1 shadow-card rounded-0 bg-image-cards bg-opacity-75 text-white h-100">
                    <h4 class="card-header border-0 bg-transparent">Celotna slika z 22 kartami</h4>
                    <div class="card-body">
                        <div v-html="opisAll"></div>
                    </div>
                    <button class="btn btn-warning card-footer border-0 bg-tarot rounded-0 rounded-0 fs-5 fw-bold"
                            @click="showModalWholePicture = true">
                        Obrni
                    </button>
                </div>
            </div>
        </div>

        <!-- showModalFool -->
        <vue-final-modal v-model="showModalFool"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalFool = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Poglej preden skočiš</span>

            <div class="modal__content">
                <div class="container text-center">
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsFool[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsFool[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsFool[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 text-center"></div>
                        <div class="col-3 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsFool[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsFool[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsFool[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsFool[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsFool[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsFool[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3"></div>
                    </div>
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsFool[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsFool[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsFool[1].name }}</h6>
                        </div>

                        <div class="col-3"></div>
                    </div>
                </div>
            </div>
        </vue-final-modal>

        <!-- showModalMagician -->
        <vue-final-modal v-model="showModalMagician"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalMagician = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Spoznajte svojo moč; uporabite svoja orodja.</span>

            <div class="modal__content">
                <div class="container text-center">
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsMagician[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMagician[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMagician[3].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsMagician[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMagician[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMagician[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 margin-top-magician-lr">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsMagician[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMagician[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMagician[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3"></div>
                        <div class="col-3 margin-top-magician-lr">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsMagician[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMagician[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMagician[5].name }}</h6>
                        </div>
                    </div>
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 margin-top-magician margin-top-magician-r">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsMagician[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMagician[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMagician[0].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-magician margin-top-magician-b">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsMagician[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMagician[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMagician[1].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>
                </div>
            </div>
        </vue-final-modal>

        <!-- showModalPriestessOne -->
        <vue-final-modal v-model="showModalPriestessOne"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalPriestessOne = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Skrivni svet sanj</span>
            <div class="modal__content">
                <div class="container text-center">
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsPriestessOne[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessOne[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessOne[1].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-high-ptiestess">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsPriestessOne[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessOne[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessOne[0].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsPriestessOne[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessOne[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessOne[2].name }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>

        <!-- showModalPriestessTwo -->
        <vue-final-modal v-model="showModalPriestessTwo"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalPriestessTwo = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Intuitivni vpogled</span>
            <div class="modal__content">
                <div class="container text-center">
                    <div class="row d-flex g-1 align-items-center justify-content-center pt-5">

                        <div class="col-3 rotate-high-priestess-two-l me-sm-5 me-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsPriestessTwo[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessTwo[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessTwo[2].name }}</h6>
                        </div>

                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsPriestessTwo[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessTwo[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessTwo[3].name }}</h6>
                        </div>

                        <div class="col-3 rotate-high-priestess-two-r ms-sm-5 ms-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsPriestessTwo[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessTwo[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessTwo[4].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center pt-4">
                        <div class="col-3 rotate-high-priestess-two-l  me-sm-5 me-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsPriestessTwo[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessTwo[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessTwo[1].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsPriestessTwo[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessTwo[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessTwo[0].name }}</h6>
                        </div>
                        <div class="col-3 rotate-high-priestess-two-r ms-sm-5 ms-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsPriestessTwo[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsPriestessTwo[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsPriestessTwo[5].name }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>

        <!-- showModalEmpress -->
        <vue-final-modal v-model="showModalEmpress"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalEmpress = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Nega se začne pri sebi</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsEmpress[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmpress[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmpress[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsEmpress[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmpress[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmpress[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsEmpress[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmpress[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmpress[3].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsEmpress[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmpress[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmpress[0].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsEmpress[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmpress[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmpress[1].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalEmperor -->
        <vue-final-modal v-model="showModalEmperor"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalEmperor = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Zmagoviti načrt</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsEmperor[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmperor[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmperor[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsEmperor[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmperor[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmperor[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsEmperor[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmperor[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmperor[3].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsEmperor[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmperor[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmperor[2].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsEmperor[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsEmperor[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsEmperor[1].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalHierophant -->
        <vue-final-modal v-model="showModalHierophant"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalHierophant = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Iskanje sveta modreca</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center ">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsHierophant[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHierophant[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHierophant[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 margin-top-hierophant">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsHierophant[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHierophant[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHierophant[2].name }}</h6>
                        </div>
                        <div class="col-3 position-relative margin-top-hierophant-plus">
                            <h6 class="text-center fw-bold">1</h6>
                            <router-link :to="randomImageUrlsHierophant[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHierophant[0].src"
                                     class="img-fluid position-absolute top-50 start-0 translate-middle-y"
                                     style=" z-index: 1;"/>
                            </router-link>

                            <h6 class="text-center fw-bold">2</h6>
                            <router-link :to="randomImageUrlsHierophant[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHierophant[1].src"
                                     class="img-fluid position-absolute top-0 start-0 rotate"
                                     style=" z-index: 2;"/>
                            </router-link>
                        </div>
                        <div class="col-3 margin-top-hierophant">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsHierophant[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHierophant[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHierophant[3].name }}</h6>
                        </div>
                    </div>
                </div>
            </div>

        </vue-final-modal>

        <!-- showModalLoversOne -->
        <vue-final-modal v-model="showModalLoversOne"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalLoversOne = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Za zaljubljene</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsLoversOne[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsLoversOne[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[1].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsLoversOne[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsLoversOne[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsLoversOne[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsLoversOne[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[5].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsLoversOne[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversOne[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversOne[6].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalLoversTwo -->
        <vue-final-modal v-model="showModalLoversTwo"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalLoversTwo = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Za samske</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsLoversTwo[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsLoversTwo[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[6].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsLoversTwo[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[1].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsLoversTwo[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[5].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsLoversTwo[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsLoversTwo[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[4].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsLoversTwo[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsLoversTwo[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsLoversTwo[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalChariot -->
        <vue-final-modal v-model="showModalChariot"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalChariot = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Neomajni razgled</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsChariot[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsChariot[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsChariot[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsChariot[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsChariot[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsChariot[2].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsChariot[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsChariot[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsChariot[1].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsChariot[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsChariot[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsChariot[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsChariot[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsChariot[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsChariot[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalStrength -->
        <vue-final-modal v-model="showModalStrength"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalStrength = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Zmaga nad strahom</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsStrength[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStrength[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStrength[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 rotate-high-priestess-two-l me-sm-5 me-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsStrength[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStrength[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStrength[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsStrength[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStrength[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStrength[4].name }}</h6>
                        </div>
                        <div class="col-3 rotate-high-priestess-two-r ms-sm-5 ms-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsStrength[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStrength[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStrength[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsStrength[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStrength[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStrength[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalHermit -->
        <vue-final-modal v-model="showModalHermit"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalHermit = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Pojdi vase</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsHermit[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHermit[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHermit[0].name }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsHermit[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHermit[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHermit[1].name }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsHermit[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHermit[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHermit[2].name }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsHermit[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHermit[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHermit[3].name }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsHermit[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHermit[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHermit[4].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalWheel -->
        <vue-final-modal v-model="showModalWheel"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalWheel = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Karmične lekcije</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsWheel[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWheel[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWheel[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsWheel[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWheel[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWheel[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsWheel[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWheel[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWheel[0].name }}</h6>
                        </div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsWheel[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWheel[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWheel[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsWheel[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWheel[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWheel[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalJustice -->
        <vue-final-modal v-model="showModalJustice"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalJustice = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Resnica v ravnotežju</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsJustice[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJustice[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJustice[5].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsJustice[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJustice[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJustice[4].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsJustice[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJustice[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJustice[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsJustice[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJustice[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJustice[0].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsJustice[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJustice[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJustice[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsJustice[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJustice[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJustice[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalHanged -->
        <vue-final-modal v-model="showModalHanged"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalHanged = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Resnica v perspektivi</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsHanged[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHanged[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHanged[0].name }}</h6>
                        </div>
                        <div class="col-3 pb-1"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsHanged[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHanged[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHanged[1].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsHanged[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHanged[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHanged[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsHanged[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHanged[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHanged[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsHanged[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsHanged[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsHanged[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalDeath -->
        <vue-final-modal v-model="showModalDeath"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalDeath = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Sprejemanje sprememb</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsDeath[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDeath[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDeath[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsDeath[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDeath[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDeath[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsDeath[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDeath[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDeath[0].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsDeath[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDeath[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDeath[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsDeath[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDeath[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDeath[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalTemperance -->
        <vue-final-modal v-model="showModalTemperance"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalTemperance = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Sveta poravnava</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsTemperance[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTemperance[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTemperance[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsTemperance[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTemperance[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTemperance[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsTemperance[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTemperance[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTemperance[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsTemperance[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTemperance[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTemperance[1].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalDevil -->
        <vue-final-modal v-model="showModalDevil"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalDevil = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Zdrave meje</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsDevil[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDevil[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDevil[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1 margin-top-devil-first">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsDevil[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDevil[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDevil[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsDevil[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDevil[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDevil[3].name }}</h6>
                        </div>
                        <div class="col-3 pb-1 margin-top-devil-first">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsDevil[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDevil[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDevil[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1 margin-top-devil-second">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsDevil[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDevil[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDevil[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3 pb-1 margin-top-devil-second">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsDevil[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsDevil[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsDevil[5].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalTower -->
        <vue-final-modal v-model="showModalTower"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalTower = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Onkraj ruševin</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsTower[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTower[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTower[0].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsTower[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTower[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTower[1].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsTower[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTower[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTower[2].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-3">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsTower[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTower[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTower[5].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsTower[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTower[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTower[3].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-devil-first">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsTower[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsTower[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsTower[4].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalStar -->
        <vue-final-modal v-model="showModalStar"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalStar = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Luč, ki vodi</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row padding-top-star d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsStar[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStar[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStar[0].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsStar[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStar[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStar[1].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsStar[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStar[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStar[2].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsStar[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStar[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStar[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsStar[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStar[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStar[4].name }}</h6>
                        </div>
                        <div class="col-3 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsStar[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsStar[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsStar[5].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalMoon -->
        <vue-final-modal v-model="showModalMoon"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalMoon = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Sence v središču</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsMoon[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMoon[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMoon[1].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsMoon[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMoon[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMoon[4].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsMoon[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMoon[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMoon[0].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsMoon[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMoon[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMoon[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsMoon[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsMoon[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsMoon[3].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalSun -->
        <vue-final-modal v-model="showModalSun" classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalSun = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Najboljši obraz za naprej</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">

                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsSun[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsSun[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[2].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsSun[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[6].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsSun[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[0].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsSun[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[3].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsSun[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[5].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsSun[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsSun[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsSun[4].name }}</h6>
                        </div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalJudgement -->
        <vue-final-modal v-model="showModalJudgement"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalJudgement = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Upoštevajte klic</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsJudgement[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJudgement[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJudgement[4].name }}</h6>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-2"></div>
                        <div class="col-2"></div>
                        <div class="col-2">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsJudgement[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJudgement[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJudgement[5].name }}</h6>
                        </div>
                    </div>
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-2 pb-1 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsJudgement[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJudgement[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJudgement[2].name }}</h6>
                        </div>
                        <div class="col-2 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsJudgement[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJudgement[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJudgement[1].name }}</h6>
                        </div>
                        <div class="col-2 pb-1 margin-top-fool">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsJudgement[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJudgement[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJudgement[3].name }}</h6>
                        </div>
                    </div>
                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-2"></div>
                        <div class="col-2 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsJudgement[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsJudgement[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsJudgement[0].name }}</h6>
                        </div>
                        <div class="col-2"></div>
                    </div>

                </div>
            </div>
        </vue-final-modal>

        <!-- showModalWorld -->
        <vue-final-modal v-model="showModalWorld"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalWorld = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Sence v središču</span>
            <div class="modal__content">
                <div class="container text-center">

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3"></div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">7</h6>
                            <router-link :to="randomImageUrlsWorld[6].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[6].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[6].name }}</h6>
                        </div>
                        <div class="col-3"></div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">5</h6>
                            <router-link :to="randomImageUrlsWorld[4].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[4].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[4].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">6</h6>
                            <router-link :to="randomImageUrlsWorld[5].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[5].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[5].name }}</h6>
                        </div>
                        <div class="col-3 pb-1 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">1</h6>
                            <router-link :to="randomImageUrlsWorld[0].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[0].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[0].name }}</h6>
                        </div>
                    </div>

                    <div class="row d-flex g-1 align-items-center justify-content-center">
                        <div class="col-3 pb-1 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">2</h6>
                            <router-link :to="randomImageUrlsWorld[1].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[1].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[1].name }}</h6>
                        </div>
                        <div class="col-3 pb-1">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">3</h6>
                            <router-link :to="randomImageUrlsWorld[2].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[2].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[2].name }}</h6>
                        </div>
                        <div class="col-3 pb-1 margin-top-empress">
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">4</h6>
                            <router-link :to="randomImageUrlsWorld[3].to" class="text-decoration-none">
                                <img :src="randomImageUrlsWorld[3].src" class="img-fluid"/>
                            </router-link>
                            <h6 class="text-center bg-dark bg-gradient font-size-sm text-white m-0 p-1 fw-bold">
                                {{ randomImageUrlsWorld[3].name }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>

        <!-- showModalWholePicture -->
        <vue-final-modal v-model="showModalWholePicture"
                         classes=" modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class=" modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalWholePicture = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">Celotna slika z 22 kartami</span>
            <div class="modal__content">
                <div class="container">
                    <ol>
                        <li><b>Norec</b>: Kje si zdaj na svojem potovanju?</li>
                        <li><b>Čarovnik</b>: Vaše naravne spretnosti/sposobnosti, ki vam bodo pomagale na vašem
                            potovanju.
                        </li>
                        <li><b>Visoka svečenica</b>: Vaša povezava z vašo intuicijo.</li>
                        <li><b>Carica</b>: Kako negujete sebe/druge.</li>
                        <li><b>Cesar</b>: Pravila ali strukture, ki trenutno urejajo vaše življenje.</li>
                        <li><b>Hierofant</b>: Kako religija/duhovnost igra vlogo v vašem življenju in na vaši poti.</li>
                        <li><b>Ljubimci</b>: Kako se izražati v ljubezni.</li>
                        <li><b>The Chariot</b>: Za kaj si trenutno prizadevate.</li>
                        <li><b>Prednosti</b>: Vaše največje področje moči.</li>
                        <li><b>Puščavnik</b>: Kako preživeti čas v samoti.</li>
                        <li><b>Kolo sreče</b>: Kako se karma trenutno zrcali na vaše življenje.</li>
                        <li><b>Pravica</b>: Težka resnica, ki jo morate slišati.</li>
                        <li><b>Obešeni mož</b>: Vprašanje ali miselnost, ki zahteva spremembo perspektive.</li>
                        <li><b>Smrt</b>: Nekaj v vašem življenju, ki se bliža koncu svojega cikla.</li>
                        <li><b>Zmernost</b>: Kako lahko najbolje dosežete stanje ravnovesja.</li>
                        <li><b>Hudič</b>: Navade/skušnjave/odvisnosti itd. vaz držijo nazaj in se vas oklepajo.</li>
                        <li><b>Stolp</b>: Področje vašega življenja, ki ga je treba temeljito prenoviti.</li>
                        <li><b>Zvezda</b>: Način, kako lahko najbolje prispevate svetu ali sebi.</li>
                        <li><b>Luna</b>: Posnetek vaše sence; del sebe, ki ga zakopljete ali skrijete.</li>
                        <li><b>Sonce</b>: Kako vas vidijo drugi; zunanji izraz vas samih.</li>
                        <li><b>Sodba</b>: Kaj je tisto, ki vas kliče, da morate dvigniti in se s tem soočiti.</li>
                        <li><b>Svet</b>: Kako lahko najbolje dosežete izpolnitev v tem življenju ali na tej stopnji
                            svojega potovanja.
                        </li>
                    </ol>

                </div>
            </div>
        </vue-final-modal>
    </div>
</div>


