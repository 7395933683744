<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            {{ naslov }}
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/kabala">Kabala</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">{{ naslov }}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot">
    <div class="container p-3">

        <div class="py-4 text-center">
            <h1>{{ naslov }}</h1>
            <div class="lead text-center">{{ kabalaRek }}</div>
            <div class=" d-none d-md-block">
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>
        </div>

        <div class="row row-cols-1 row-cols-xl-3 g-4 pt-3">
            <!-- Meditacija na mirnost telesa in tratak -->
            <div class="col">
                <div class="card bg-dark shadow-card bg-image-cards border-dark rounded-0 bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ naslovKabala }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text" v-html="kabalaNaKratko"></p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                            @click="modalKabala = true">
                        {{ button }}
                    </button>
                </div>
            </div>
            <!-- Meditacija na dih in čakre -->
            <div class="col">
                <div class="card bg-dark shadow-card bg-image-cards border-dark rounded-0 bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ naslovSefirot }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text" v-html="kabalaSefirot"></p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                            @click="modalSefirot = true">
                        {{ button }}
                    </button>
                </div>
            </div>
            <!-- Meditacija na tarot -->
            <div class="col">
                <div class="card bg-dark shadow-card bg-image-cards border-dark rounded-0 bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ naslovNadangeli }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text" v-html="nadangeli"></p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                            @click="modalNadangeli = true">
                        {{ button }}
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal KAJ JE KABALA -->
        <vue-final-modal v-model="modalKabala" classes="modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="modalKabala = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">{{ naslovKabala }}</span>

            <div class="modal__content">
                <div class="container">
                    <span v-html="opisKabala"></span>
                </div>
            </div>
        </vue-final-modal>

        <!-- Modal OPIS SEFIROTOV -->
        <vue-final-modal v-model="modalSefirot" classes="modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="modalSefirot = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">{{ naslovSefirot }}</span>

            <div class="modal__content">
                <div class="container">
                    <h4 class="fw-bold">Kether</h4>
                    <div v-html="keter"></div>
                    <h4 class="fw-bold">Chokmah</h4>
                    <div v-html="chokmah"></div>
                    <h4 class="fw-bold">Binah</h4>
                    <div v-html="binah"></div>
                    <h4 class="fw-bold">Chesed</h4>
                    <div v-html="chesed"></div>
                    <h4 class="fw-bold">Geburah</h4>
                    <div v-html="geburah"></div>
                    <h4 class="fw-bold">Tiphareth</h4>
                    <div v-html="tiphareth"></div>
                    <h4 class="fw-bold">Netzach</h4>
                    <div v-html="netzach"></div>
                    <h4 class="fw-bold">Hod</h4>
                    <div v-html="hod"></div>
                    <h4 class="fw-bold">Yesod</h4>
                    <div v-html="yesod"></div>
                    <h4 class="fw-bold">Malkuth</h4>
                    <div v-html="malkuth"></div>
                </div>
            </div>
        </vue-final-modal>

        <!-- Modal OPIS NADANGELOV -->
        <vue-final-modal v-model="modalNadangeli"
                         classes="modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="modalNadangeli = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">{{ naslovNadangeli }}</span>

            <div class="modal__content">
                <div class="container">
                    <span v-html="opisNadangeli"></span>
                    <h4 class="fw-bold">Michael</h4>
                    <div v-html="michael"></div>
                    <h4 class="fw-bold">Uriel</h4>
                    <div v-html="uriel"></div>
                    <h4 class="fw-bold">Raphael</h4>
                    <div v-html="raphael"></div>
                    <h4 class="fw-bold">Gabriel</h4>
                    <div v-html="gabriel"></div>
                </div>
            </div>
        </vue-final-modal>

    </div>
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>{{ naslovMolitev }}</h1>
            <div class="lead text-center">{{ molitevRek }}</div>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>

        <p v-html="molitevOpis"></p>

        <div class="row row-cols-1 row-cols-xl-3 g-4 pt-3">
            <div class="col" v-for="(molitev, index) in molitve" :key="index">
                <div class="card text-center bg-dark border-dark rounded-0 shadow-card bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent">{{ molitev.naslov }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text" v-html="molitev.molitev"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
