export default {
    data() {
        return {
            naslov: "Sadhana XXI – Svet",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            uvodPrvi:
                '<p>Začutite svetlobo, ki se širi. Začutite kako postajate lahki in sveži, začutite kako se vsa ta svežina in svoboda dotika vseh na svetu. Začutite kako začenjate postati odprti in kako se začenjate zavedati, da obstaja več kot pa samo telo. Zavedajte se svojega svetlobnega telesa in zavedajte se kako se začenjate dvigati vse višje in višje. Vizualizirajte kako se vaše telo vse bolj oddaljuje od tal in se dviga vse višje do neba... in še naprej... vse do tja, kjer gledate svet pred seboj. Vidite kako se svet vrti in vidite kako se svet umirja. Vsa energija sveta se umirja. Začutite mir in svetlobo. Začutite lahkost.</p>' +
                '<p>Dvignite desno roko in čutite kako iz vašega svetlečega telesa skozi roko začne potovati svetloba proti svetu in ta svet pred seboj napolnete s svetlobo miru, zavedanja, mirnosti, strpnosti, varnosti,... Začutite kako svet postaja prelepo mesto za vas. Začutite kako postajate vse bolj mirni in lahki. Pričnite se zavedati, da ste več kot samo telo, ki ga vidite in občutite. Zavedajte se, da je vse brezmejno. Zavedajte se, da začenjate s transformacijo. Tukaj si lahko rečete:</p>' +
                '<p class="fst-italic p-3">"To pot transformacije začenjam zdaj. Pripravljen/a sem na nova odkritja in pripravljen/a sem na nove izzive. Nisem iz tega sveta ampak sem otrok onstranstva. Pripravljen/a sem na pot nazaj. Vse svoje fizične, čustvene, mentalne in intuitivne sposobnosti posvečam izvedbi velikega dela <sup class="fw-bold">* <sup>2</sup></sup>. Ni del mene, ki nebi bil božanski."</p>' +
                '<p>Prejšnje si lahko ponovite 3x. Ko končate vidite, kako se bela svetloba spreminja v indigo. Sedaj žari v indigo barvi, in umirjen, prijeten, svoboden svet vam pričenja vračati energijo v indigo barvi in vam pričenja polniti srce. Ta svetloba gre v srce in se pričenja širiti iz srca po celotnem telesu in vidi se kako žari svetloba iz telesa.</p>' +
                '<p>Sedaj ponovite v sebi mantro <i>OM</i> in čutite kako vibracije prežemajo celotno telo in celoten svet. S svetom ste povezani in kljub temu, da ste zunaj v vesolju in gledate zemljo čutite kako ste prizmeljeni. Svet postaja mesto, kjer se lahko igrate, rastete in živite umirjeno. Svet postaja mesto, kjer boste lahko razvijali svoje sposobnosti in se poglabljali in dvigali. Čutite, kako ste zadovolji s samim sabo in kako ste zadovoljni s svetom, ki je pred vami.</p>' +
                '<p>Nato vas vleče v svet in pride do združitve. Ste eno z Materjo Zemljo, ki vas polni in obdaja z ljubeznijo. V sebi čutite moč in mir. Čutite pripravlenost in motivacijo.</p>',

            uvodDva:
                '<p>Po mesecu dni vsakodnevnega izvajanja prve stopnje, lahko preidete na naslednjo, to stopnjo. Pričnite se zavedati, da sedite na rumenem kvadratu. Kvadrat je rumene barve in se sveti. Kvadrat je ravno toliko širok, da lahko na njem sedite. Počutite se popolnoma varno. Ste varni in prizemljeni. Medtem ko sedite čutite vse smeri neba vendar tej prostranosti okoli vas ni konca. Je brezmejni prostor. Pomembno je, da to čutite.</p>' +
                '<p>Po nekaj trenutkih pričnite čutiti po vrstnem redu naslednje:</p>' +
                '<ol><li>Stanje naravnega zadovoljstva - po nekaj trenutkih 3x ponovite mantro <b>VAM</b> in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Stanje naravnega užitka - po nekaj trenutkih 3x ponovite mantro <b>ŠAM</b> in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Užitek v obvladovanju strasti - po nekaj trenutkih 3x ponovite mantro <b>ŠAM</b> in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Blaženost v koncentraciji - po nekaj trenutkih 3x ponovite mantro <b>SAM</b> in čutite brezmejno vibracijo, ki se širi v vse smeri.</li></ol>' +
                '<p>Okoli kvadrata se sedaj pojavijo štirje lotosovi cvetovi rdeče, krvave barve in svetijo v vse smeri neba. So prelepe krvave barve, ki vam prinašajo varnost, mirnost, zadovoljstvo in fokus. Kmalu se pod vami pojavi še trikotnik obrnjen nazaj, ki je svetlo rdeče barve s piko na sredini iz katere sveti mala bela svetloba navzgor proti glavi in naprej. Sedaj si predstavljajte celotno sliko in še enkrat ponovite mantre: <b>VAM</b> (in vizualizirajte prvi cvet čakre kako še bolj sveti in pojdite naprej v smeri urinega kazalca), <b>ŠAM</b>, <b>ŠAM</b>, <b>SAM</b> in nato <b>LAM</b> 108x za celotno čakro.</p>' +
                '<p>Če želite lahko tukaj pričnete z uporabo mantre <b>OM GAM SWAHA</b> ali <b>OM GLAUM SWAHA</b> in jo ponovite 108x. Medtem ko jo ponovite čutite, kako se iz vas širi vsa ta energija do ostalih ljudi in živali, kako se ta energija širi v vse smeri neba in napaja ves svet.</p>',

            uvodGlavna: '<p>Počasi in z občutkom si recite: "Nisem od tega sveta, ampak sem otrok onstranstva" in čutite, kako ste več kot samo telo in čutite kako ste del vsega. Ponovite naslednje: "Moj dom je daleč in hrepenim po enosti. Moja pot nazaj k enosti se pričenja sedaj. Svoje fizične, čustvene, mentalne in intuitivne sposobnosti posvečam izvedbi velikega dela" in se zavedajte, da od tega trenutka naprej sledite tej vrnitvi nazaj v enost, v onstranstvo in se zavezujete, da boste vsako svoje dejanje posvetili temu, da dosežete to enost in si ponovite naslednje: "Naj čutila mojega telesa, občutki mojih čustev, misli mojega uma in vpogledi moje intuicije služijo temu, da dosežem enost z vsem. Ni dela mene, ki ne bi bil božanski," in vidite čutite, kako postajate brezmejni, čutite neomejenost v vseh smereh, v sebi in vidite kako se vsa svetloba širi v vse smeri in kako prežema ves obstoj. Sedaj si ponovite: "Odločim se, da bom vse dogodke v svojem življenju obravnaval kot del dialoga med menoj in svojim Jazom. Naj bo vsak del mojega bitja in vsako dejanje mojega življenja posvečeno namenu vrnitve v Enost in brezmejnost." Vse kar naredite, vse kar izgovorite in vse kar mislite posvečate temu, da dosežete najvišje. Ste kot menih, ki svoje življenje posveti religiji. Vi posvečate svoje življenje vrnitvi v to enost z vsem, v to brezmejno energijo.</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

            dodatno: '<ol><li>Vsak dan si vzemite nekaj trenutkov svojega časa, se ustavite in poglejte okoli sebe. Zavedajte se sebe in okolice. Opazite kje ste in kaj delate.</li>' +
                '<li>Vsak dan si vzemite čas za sprehod in med sprehodom se poskušajte zavedati ko hodite, zavedajte se kako se stopala dotikajo tal.</li></ol>',

            opombe: '<ol><li>Delajte to dokler ne čutite, da se je vaše telo dovolj sprostilo. Če čutite, da se vaše telo še vedno ni dovolj sprostilo, potem lahko delate samo pripravo in zaključek dokler ne čutite, da lahko grete naprej na uvod. Lahko nekaj tedno izvajate kakšno vodeno sproščanje ali joga nidro.</li>' +
                '<li>Ko se govori o velikem delu se cilja na desjtvo, da vzpostavimo stik z univerzalno zavestjo, da individualno zavest združimo z univerzalno.</li></ol>',
        }
    }
}
