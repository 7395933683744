export default {
    data() {
        return {
            naslov: "Sadhana III - Cesarica",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            dolgaGayatri: '<p>Sledi dolga, podaljšana verzija Gayatri mantre. Lahko od sedaj naprej uporabljate to mantro in spet se umaknete in naredite 10.000 ponovitev v enem mesecu.</p><p class="text-center">oṃ bhūr oṃ bhuvaḥ oṃ svaḥ<br>oṃ māha oṃ jānaha oṃ tāpaha oṃ sātyam<br>tat savitur vareṇyaṃ<br>bhargo devasya dhīmahi<br>dhiyo yo naḥ pracodayāt swāhā<br></p><p><i>"O samosijajna luč, ki si rodila vse loke, ki si vredna čaščenja in se pojavljaš skozi orbito sonca, razsvetli naš razum."</i></p><p>Ta močna mantra prikliče duhovne ravni – in, kjer je primerno, povezane fizične čakre. Korespondence so naslednje:</p><p><b>Om Bhuh</b> - zemeljska ravnina (prva čakra)<br><b>Om Bhuvaha</b> - atmosferska ravnina (druga čakra)<br><b>Om Swaha</b> - sončna regija (tretja čakra)<br><b>Om Maha</b> - prva duhovna regija onkraj sonca; srčne vibracije (četrta čakra)<br><b>Om Janaha</b>  - druga duhovna regija onkraj sonca; moč božanske duhovne besede (peta čakra)<br><b>Om Tapaha</b> - tretja duhovna regija onkraj sonca; sfera prednikov (šesta čakra) (Ta loka predstavlja najvišje kraljestvo duhovnega razumevanja, ki ga lahko dosežemo, medtem ko smo še vedno identificirani z individualnim obstojem.)<br><b>Om Satyam</b> bivališče najvišje resnice; potopljenost v Najvišje (sedma čakra)<br><b>Om Tat Savitur Varenyam</b> – tisto kraljestvo Resnice, ki je onkraj človeškega razumevanja<br>Bhargo Devasya Dhimahi Dhiyo Yonaha Prachodayat</b>  - na tistem mestu, kjer so vsi nebeški vseh sfer prejeli razsvetljenje, prijazno razsvetli naš intelekt.<br>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

        }
    }
}
