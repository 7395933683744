export default {
    data() {
        return {
            naslov: "Sadhana IV - Cesar",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            chakrasadhana: '<p>Tukaj združite vse čakre in meditirate na čakre na naslednji način: Zavedajte se svoje hrbtenice in pričnete z zavedanjem muladhara čakre in vizualizirate čakro v predelu muladhare v celoti in začnete recitirati mantre lotosovih cvetov in semensko mantro muladhare (<b>LAM</b>), medtem vsem tem pa prav tako vizualizirate kako sedite na lotosu rdeče barve s štirimi cvetovi, kako žari v vsej svoji veličini. Ko mantrate čutite cvibracije kako se širijo iz te točke v vse smeri neba. Tako nadaljujete z vsako čakro do Ajne (barva lotosa na katerem sedite so: swadhisthana - oranžen lotos in 6 cvetov; manipura - rumen lotos in 10 cvetov; anahata - zelen lotos in 12 cvetov; vishuddhi - moder lotos in 16 cvetov in ajna bel lotos in 2 cvetova).</p><p>Ko z zavedanmjem potujete po vsaki čakri navzgor, katere se nahajajo v hrbtenici, se potem ko pridete do ajne pomaknete navzdol, vendar tokrat tako, da sedite na belem lotosu z 1000 cvetovi in ponavljate samo semenske mantre pripadajoče čakre (Ajna - LAM + vizualizacija čakre, Vishuddhi - HAM + vizualizacija čakre, Anahata - YAM + vizualizacija čakre, Manipura - RAM + vizualizacija čakre, Swadhisthana - VAM + vizualizacija čakre in Muladhara - LAM + vizualizacija čakre). Ko pridete do muladhare greste spet navzgor v obratnem vrstnem redu.</p><p>Ko prispete drugič do Ajna čakre se pomaknete na vrh glave in potem tukaj ostanete in čutite kako se ta lotos s 1000 cvetovi napolni z svetlobo in vse bolj raste v vse smeri neba. Lahko uporabite mantro <b>OM</b>. Čutite svojo avro kako blesti v vsej svoji veličini, in čutite transparentnost svojega telesa in uma. Čutite kako gre vse skozi vas, transparentnost. Ko končate se vrnite nazaj dol do muladhare po vrstnem redu opisan prej.</p>',

            mahamriyunjay: '<p class="text-center"><i>Meditiram na triokega (Šivo), ki je dišeč (v stanju najvišje blaženosti) in ki vzdržuje vsa živa bitja. Naj nas osvobodi (večnega kroga rojstev in) smrti. Naj nas popelje v nesmrtnost, tako kot je kumara osvobojena svojega suženjstva (trte, na katero je pripeta).</i></p><p class="text-center">oṃ tryámbakaṃ yajāmahe<br>sugandhíṃ puṣṭi-vardhánam<br>urvārukamíva bandhánān<br>mṛtyor mukṣīya mā \'mṛtā́t swaha<br></p><p>2 tedna ponavljajte mantro 27x, nato naslednja 2 tedna 54x in potem po občutku pričnite z 108x na dan.</p><dt>Prednosti mantranja te mantre</dt><dd>Mantra Mahamrityunjaya je močna kombinacija zvokov, ki, če jo ponavljamo z vero, predanostjo in vztrajnostjo v daljšem časovnem obdobju, vodi ne le do zmage nad strahom pred smrtjo, ampak sčasoma do zmage nad samo smrtjo ali mokšo (osvoboditvijo). Zato je znana kot "moksha mantra". Je stimulativna in grelna (za razliko od Gayatri mantre, ki pomirja in hladi). Podarja dolgoživost in je zasnovana za zdravljenje bolezni. Odganja zle ali negativne sile tako, da ustvari zaščitni psihični ščit okoli praktikanta. Pravijo, da uniči žalost in revščino ter izpolni vse želje. Vsakdo, ki želi odstraniti ovire v življenju in premagati težke situacije ali bolezen, naj to mantro ponavlja redno.</dd>',

            meditacijaCesar: '<p>Ponovite naslednje in čutite: "Sem moč med ljudmi. Otrok zemlje sem; vendar v meni gori ogenj najvišjih nebes." Čutite kako iz zgoraj iz nebez po vas teče nektar nesmrtnosti in nadaljujte: "Naj ogenj ljubezni in dobrote tako očisti zemeljsko naravo mojega osebnega jaza, da bom vreden vstopiti v svetišče templja prave ljubezni. Samo čisti v srcu lahko resnično ljubijo. Naj v moji ljubezni ne bo jaza. Naj bo ljubljeni vse, jaz pa nič. O ljubezen, ki iz zemlje izvabljaš iskre svetosti, vodi me k vrtnici na zlatem križu! O ljubezen, ki si življenjska sila vesolja in hranilec moje duše, daj mi moč, da se povzpnem k tebi! Iz senc me vodi v svoj sočutni sijaj!" Čutite kako ste prežeti z nektarjem in čutite ljubezen in sočutje do vsega.</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

        }
    }
}
