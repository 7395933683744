<div class="container-fluid text-white bg-tarot-index">
    <div class="container">
        <div class="row p-4 d-flex align-items-center justify-content-center">
            <div class="col-md-6 p-md-5">
                <!-- VIDNO POD MD -->
                <div class="d-md-none text-center">
                    <h1>Tarot za vašo rast!</h1>
                    <div class="fs-4">Ko spustimo potrebo po temu, da smo nekaj in smo pripravljeni biti nič, se odpremo
                        vsemu!
                    </div>
                </div>

                <!-- VIDNO NAD MD -->
                <div class="d-none d-md-block">
                    <h1>Tarot za vašo rast!</h1>
                    <div class="fs-4">Ko spustimo potrebo po temu, da smo nekaj in smo pripravljeni biti nič, se odpremo
                        vsemu!
                    </div>
                </div>

            </div>
            <div class="col-md-6 text-center">
                <div class="bd-highlight">
                    <img src="@/assets/thefoolindex.png" ondragstart="return false;"
                         class="img-fluid animate__animated animate__flip">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-4 bg-dark bg-gradient text-white">
    <div class="container p-5">
        <div class="pb-3 text-center">
            <img src="~@/assets/hrline-white.png" class="img-fluid pb-3 text-center" alt="">
            <p v-html="pozdravi" class="fs-5"></p>
            <img src="~@/assets/hrline-white.png" class="img-fluid text-center" alt="">

        </div>
    </div>
</div>

<div class="container-fluid bg-header">
    <div class="container py-5">
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div class="col " v-for="(card, index) in cards" :key="index">
                <router-link class="text-decoration-none" :to="card.to">
                    <div class="card shadow-card bg-dark text-white text-center border-dark rounded-0 bg-image-cards bg-opacity-75">
                        <div class="row g-0 d-flex align-items-center justify-content-center">
                            <div class="card-body">
                                <h2 class="card-title">{{ card.povezava }}</h2>
                                <img src="~@/assets/hrline.png" class="img-fluid pb-3 text-center" alt="">
                                <p class="card-text" v-html="card.opis"></p>
                            </div>
                            <div class="btn btn-warning card-footer rounded-0 border-0 bg-header fs-5 fw-bold">
                                {{ card.link }}
                            </div>

                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-header">
    <div class="container py-5">
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div class="col " v-for="objava in blogPost"  :key="objava._id">
                <router-link class="text-decoration-none" :to="`/blog/${objava._id.$oid}`">
                    <div class="card shadow-card bg-dark text-white text-center border-dark rounded-0 bg-image-cards bg-opacity-75">
                        <div class="row g-0 d-flex align-items-center justify-content-center">
                            <div class="card-body">
                                <h2 class="card-title">test</h2>
                                <img src="~@/assets/hrline.png" class="img-fluid pb-3 text-center" alt="">
                                <p class="card-text" v-html="truncateText(objava.vsebina, 150)"></p>
                            </div>
                            <div class="btn btn-warning card-footer rounded-0 border-0 bg-header fs-5 fw-bold">
                                kgh
                            </div>

                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid py-4 bg-dark bg-gradient text-white">
    <div class="container p-2">
        <div class="text-center pb-3">
            <h2 class="fw-bold">Karta dneva je: {{ currentImageName }}</h2>
            <p class="lead"> Naključno izbrana karta iz tarota, ki se menja vsakih 24 ur. Je karta dneva in na podana
                vprašanja si lahko odgovorite, o njih razmislite in si jih zapišete! Vprašanje, ki v vas sproži nek
                odgovor ali se vam zazdi blizu, je vprašanje na katerega je vredno odgovoriti in se v področje
                poglobiti.</p>
        </div>
        <div class="row g-4 text-center d-flex align-items-center justify-content-center">
            <!-- KARTA DNEVA -->
            <div class="col-lg-3">
                <router-link :to="currentImageLink" class="text-decoration-none">
                    <img :src="currentImageSrc" class="img-fluid shadow" ondragstart="return false;"/>
                </router-link>
            </div>
            <div class="col-lg-9">
                <p v-html="currentImageText"></p>
            </div>
        </div>
    </div>
</div>
