<div class="sticky-top">
    <nav class="navbar navbar-dark bg-header shadow position-relative index-two">
        <div class="container">
            <router-link class="navbar-brand" to="/">

                <div class="d-none d-lg-block">
                    <img src="~@/assets/pentagram-logo.png" ondragstart="return false;"
                         class="position-absolute top-0 start-0 translate-middle" alt="tarot"/>
                    <div class="ps-3 fs-2">Tarot zate!</div>
                </div>

                <div class="d-lg-none">
                    <div class="display-2">
                        <img src="~@/assets/pentagram-logo.png" ondragstart="return false;"
                             class="d-inline-block align-text-center" alt="tarot"/>
                        Tarot zate!
                    </div>
                </div>

            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="offcanvas offcanvas-end bg-header" tabindex="-1" id="offcanvasNavbar"
                 aria-labelledby="offcanvasNavbarLabel">
                <div class="offcanvas-header">
                    <h4 class="offcanvas-title " id="offcanvasNavbarLabel">Tarot zate!</h4>
                    <button type="button" class="btn-close btn-close-dark text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div class="offcanvas-body bg-image-header text-center">
                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/">Domov</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/tarot">Tarot</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/vaje">Tarot vaje</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/tarotbranje">Tarot postavitve</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/kabala">Kabala</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/kliphot">Qliphot</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/meditacija">Meditacija</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/sadhana">Sadhana</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/blog">Blog</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/literatura">Literatura</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/vpr-odg">V&O</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/kontakt">Kontakt</router-link>
                        </li>
                        <li class="nav-item" data-bs-dismiss="offcanvas" aria-label="Close">
                            <router-link class="nav-link text-white" to="/login">Login</router-link>
                        </li>
                        <li class="nav-item pt-2" data-bs-dismiss="offcanvas" aria-label="Close">
                            <a class="" href="https://www.instagram.com/tarotzate/" target="_blank"><img src="~@/assets/insta.png" alt="" height="35"></a>
                            <a class="" href="https://www.facebook.com/tarotzate" target="_blank"><img src="~@/assets/face.png" alt="" height="35"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</div>
