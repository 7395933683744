export default {
    data() {
        return {
            naslov: "Sadhana VIII - Moč",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            meditacijaVishudhi:
                '<p>Pričnite se zavedati, da sedite na krogu v katerem je svetleča polna luna na vrhu in pod to luno je kaplja, ki predstavlja nektar. Celotna slika ima ravno tolikšen premer, da lahko na njem sedite. Okoli kroga je 16 lotosovih cvetov, ki so v barvi sivke ali dimno vijolične. Počutite se v popoolni harmoniji. Ste pomirjeni in prizemljeni, polni ljubezni, ki jo delite med vse. Medtem ko sedite čutite vse smeri neba vendar tej prostranosti okoli vas ni konca. Je brezmejni prostor. Pomembno je, da to čutite.</p>' +
                '<p>Po nekaj trenutkih pričnite vizualizirati, kako sedite na lotosu z 12imi globoko rdečimi cvetovi v katerem je zlat heksagram. Okoli vas se pojavijo vsi ljudje sveta, vsa bitja, duhovi, demoni,... in iz vašega srca se širi svetloba do vseh bitij in čutite, kako se vsi osvobajajo nevednosti, trpljenja.</p>' +
                '<p>Sedaj vidite celotno sliko čakre in ponovite naslednje mantre še enkrat: <b>KAM</b> (in vizualizirajte prvi cvet čakre kako še bolj sveti in pojdite naprej v smeri urinega kazalca), <b>KHAM</b>, <b>GAM</b>, <b>GHAM</b>, <b>NAM</b>, <b>ČAM</b>, <b>ČHAM</b>, <b>JAM</b>, <b>JHAM</b>, <b>JHAM</b>, <b>NAM</b>, <b>TAM</b> in <b>THAM</b>. Nato ponovite <b>YAM</b> 108x in čutite, kako je vse v harmoniji v vas in okoli vas v vseh bitjih.</p>',


            durgaNames: '<b>durgā durgārti-śamanī durgā-padvinivāriṇī<br>durgama-cchedinī durga-sādhinī durga-nāśinī</b><br><ol><li>Uteha težavam</li><li>Ta, ki pomiri težave</li><li>Odganjalka nadlog</li><li>Ta, ki poseka težave</li><li>Ta, ki izvaja disciplino odganjanja težav</li><li>Uničevalka težav</li></ol><b>durgatod-dhāriṇī durga-nihantrī durgamāpahā<br>durgamajñānadā durga daityaloka-davānalā</b><br><ol><li>Ta, ki je bič težavam</li><li>Ta, ki je poguba težav</li><li>Ta, ki premeri težave</li><li>Ta, ki povzroči, da se težave onesvestijo</li><li>Ta, ki uniči svet motečih misli</li></ol><b>durgamā durgamālokā durgamātmasvarūpiṇī<br>durgamārgapradā durgamavidyā durgamāśritā</b><br><ol><li>Mati težav</li><li>Zaznava težav</li><li>Vrojena narava duše težav</li><li>Ta, ki preiskuje težave</li><li>Znanje o težavah</li><li>Odrešitev od težav</li></ol><b>durgamajñāna-saṃsthānā durgamadhyāna-bhāsinī<br>durga-mohā durgamagā durgamārtha-svarūpiṇī</b><br><ol><li>Neprekinjen obstoj težav</li><li>Ta, katere meditacija ostaja odlična tudi v težavah</li><li>Ta, ki zavaja težave</li><li>Ta, ki razrešuje težave</li><li>Ta, ki je vrojena narava predmeta težav</li></ol><b>durgam-āsura-saṃhantrī durgam-āyudha-dhāriṇī<br>durgamāṅgī durgamatā durgamyā durgameśhvarī</b><br><ol><li>Uničevalka egoizma težav</li><li>Nosilka orožja zoper težave</li><li>Rafinerija težav</li><li>Ta, ki je onstran težav</li><li>Težko dostopna</li><li>Vladarka težav</li></ol><b>durgabhīmā durgabhāmā durgabhā durgadāriṇī</b><br><ol><li>Ta, ki je težavam strašna</li><li>Gospodarica težav</li><li>Razsvetljevalka težav</li><li>Ta, ki odseka težave</li></ol>',

            durgaMantra: '<p class="text-center"><b>Om Hring Šring Dung Durgayai Namah</b></p><p>Vsak dan zjutraj 108x po 32 imenih Durge.</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

        }
    }
}
