export default {
    data() {
        return {
            naslov: "Sadhana X – Kolo sreče",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            anahata:
                '<p>Pričnite se zavedati, da sedite na heksagramu ali šesterokraki zvezdi zlate barve. Heksagram ima ravno tolikšen premer, da lahko na njem sedite. Okoli heksagrama je krog, ki ima 12 lotosovih cvetov, ki so globoko rdeče barve. Počutite se v popoolni harmoniji. Ste pomirjeni in prizemljeni, polni ljubezni, ki jo delite med vse. Medtem ko sedite čutite vse smeri neba vendar tej prostranosti okoli vas ni konca. Je brezmejni prostor. Pomembno je, da to čutite.</p>' +
                '<p>Po nekaj trenutkih pričnite vizualizirati, kako sedite na lotosu z 12imi globoko rdečimi cvetovi v katerem je zlat heksagram. Okoli vas se pojavijo vsi ljudje sveta, vsa bitja, duhovi, demoni,... in iz vašega srca se širi svetloba do vseh bitij in čutite, kako se vsi osvobajajo nevednosti, trpljenja.</p>' +
                '<p>Sedaj vidite celotno sliko čakre in ponovite naslednje mantre še enkrat: <b>KAM</b> (in vizualizirajte prvi cvet čakre kako še bolj sveti in pojdite naprej v smeri urinega kazalca), <b>KHAM</b>, <b>GAM</b>, <b>GHAM</b>, <b>NAM</b>, <b>ČAM</b>, <b>ČHAM</b>, <b>JAM</b>, <b>JHAM</b>, <b>JHAM</b>, <b>NAM</b>, <b>TAM</b> in <b>THAM</b>. Nato ponovite <b>YAM</b> 108x in čutite, kako je vse v harmoniji v vas in okoli vas v vseh bitjih.</p>',

            navagrahaSadhana: '<p>Ponovite naslednjo mantro pozdrava vsem planetom enkrat na začetku in enkrat na koncu:</p><p><b>Om Aaditya Somaya Mangalaya Budhaya ca |<br>Guru Shukra Shanibhyashcharaya Rahave Ketave Namah ||</b></p><p>Vsako naslednjo mantro ponovite 11x:</p><dt>Surya (Sonce) - <b>Om Hrang Hring Hraung Sah Suryay Namah</b></dt><dd>Čutite moč, vitalnost, zmogljivosti, zdravje medtem ko izgovarjate to mantro.</dd><dt>Chandra (Luna) - <b>Om Shrang Shring Shraung Sah Chandray Namah</b></dt><dd>Čutite zadovoljstvo in duševni mir, mirno in hladno glavo.</dd><dt>Mangal (Mars) - <b>Om Krang Kring Kraung Sah Kujay Namah</b></dt><dd>Čutite pogum na vseh področjih.</dd><dt>Budh (Merkur) - <b>Om Brang Bring Braung Sah Budhay Namah</b></dt><dd>Čutite hitrost in intelektualno sposobnost svojega uma in znanje na vseh področjih.</dd><dt>Guru (Jupiter) - <b>Om Grang Gring Graung Sah Gurave Namah</b></dt><dd>Zavedajte se modrosti, čutite visok položaj v družbi, bogastvo in sposobnost biti pod kontrolo povsod.</dd><dt>Shukra (Venera) - <b>Om Drang Dring Draung Sah Shukray Nama</b></dt><dd>Čutite vsesplošno udobje, čudovitih odnosov s seboj in drugimi, zavedajte se bogastva v sebi in popolne sreče.</dd><dt>Shani (Saturn) - <b>Om Prang Pring Praung Sah Shanishchray Namah</b></dt><dd>Zavedajte se sebe kot višjega od ostalih vendar kljub temu ponižnega, začutite močno in stabilno kariero, bogastvo in dobro zdravje ter svojo duhovno rast.</dd><dt>Rahu - <b>Om Bhrang Bhring Bhraung Sah Rahave Namah</b></dt><dd>Zavedajte se svoje duhovnosti, širine, statusa, okultnega.</dd><dt>Kethu - <b>Om Strang String Straung Sah Ketave Namah</b></dt><dd>Zavedajte se svoje duhovnosti, izgube ega, ponosa in zavedajte se, da vas vaša pot vodi do samospoznanja in osvoboditve.</dd><b>Om Aaditya Somaya Mangalaya Budhaya cha |<br>Guru Shukra Shanibhyashcharaya Rahave Ketave Namah ||</b><br>',

            meditacijaKolo: '<p>Ponovite naslednje in čutite: "Sprejemam plačilo za svoj trud. Kolo zakona se bo obrnilo meni v prid in vladal bom štirim kotom svojega notranjega bitja. Naj se spomnim, da sem poklican h gospodarstvu nad samim seboj in ne h gospodarstvu nad dobrinami sveta. Moje zlato bo v opoldanskem soncu ljubezni in moje srebro v polnočni luni znanja. Resnično sem srečen, da sem bil voden hoditi v luči svetih in v družbi pravičnih. Moj obstoj sega od vrha nebes do globin pekla in jaz sem gospodar vsega. Kolo se vrti v smeri dobrega: naj bom pripravljen in sposoben delovati kot palica v velikem kolesu, ki se vedno premika za lepoto, dobroto in resnico. Obrni se, kolo svete postave, da napreduje moje veliko božje telo proti cilju lastne izpolnitve!"</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

        }
    }
}
