export default {
    data() {
        return {
            naslov: "Sadhana XX – Sodba",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se kako se z vdihom vaše telo polni s pomirjajočo se svetlobo z izdihom pa izdihujete vse napetosti iz svojega celotnega telesa. Vezmite si nekaj trenutkov, da se umirite in sprostite.</p>',

            uvodPrvi:
                '<p>Sedaj se začnite zavedati notranjega stanja, notranje tišine. To je na tej stopnji malo težje, zato si recite 3x: <i>"Zavedam se notranje tišine, ki je nedotakljiva od vsega kar je zunanjega."</i> Sedaj usmerite vso svojo pozornost k fizičnim občutkom, to je pritisk svojega telesa na tla, dotik oblačil na svoje telo,... Vso svojo pozornost usmerite v to. Do delajte vse dokler izgubite zanimanje. Morda boste čutili nekakšno posebno odmaknjenost, ampak pomembno je, da ko to začutite, da se nato preselite na zavedanje zunanjega okolja. Zavedajte se vsega okoli sebe. Zavedajte se vsega kar se dogaja zunaj vas pa naj vas to moti ali pa ne. Naj do tega zaznavanja pride brez kakršnega koli napora. Vso zaznavanje mora potekati nevtralno, to je zelo pomembno. Kljub temu, da pa obstaja veliko različnih vtisov, bo zvok najbolj zaznaven.<sup>*<sup>1</sup></sup></p>' +
                '<p>Zavedajte se zunanjih zvokov. To so lahko različni zvoki, kot je na primer avto, ptice, radio, televizija, ljudje,... Karkoli je poslušajte to z nenavezanim zavedanjem, brez obsojanja, razmišljanja ali analiziranja. Samo slišite te zvoke brez razmišljanja od kod prihajajo ali od koga so. Če slišite lajež psa, ne razmišljajte o laježu psa... samo poslušajte zvok. Postavite se v vlogo gledalca, priče in ne se identificirat z nobenim zvokom. Recite si: <i>"Sem samo priča dogodkom in ti zvoki se me ne dotaknejo."</i> Nadaljujte z zavedanjem vseh zvokov v okolju, brez izbiranja. Samo sprejmajte. Delajte to nekaj minut.</p>' +
                '<p>Sedaj med vsemi zvoki izberite tistega, ki najbolj izstopa in usmerite svojo pozornost samo na ta zvok, vsi ostali pa niso pomembni. Po kakšni minuti izberite drug zvok in usmerite svojo pozornost samo na ta zvok. Ostanite pozorni, ostanite priča. Zavedajte se različnih zvokov po vrsti. In potem se spet istočasno zavedajte vseh zvokov, ki se pojavljajo. Razširite svoje zaznavanje na zunanji svet do popolnosti in slišite najmanjši zvok. Ne razmišljajte, samo zavedajte se zunanjih čutnih izkušenj.</p>' +
                '<p>Sedaj preusmerite svoje zavedanje na dihanje in čutite, kako dih priteka in odteka skozi nosnici v telo in iz njega. Zavedajte se dihanja nekaj minut. Potem spet preusmerite svoje zavedanje v zunanji svet in se spet pričnite zavedati zunanih zvokov. Tukaj lahko svoje zavedanje usmerite na vse zvoke ali pa si izberete zvok, ki je najbolj slišen in ga opazujete. Naj vas ne odnese navznoter in ne izgubite se v mislih. Poslušajte uro. Nekoga, ki kašlja. Nekoga, ki govori. Poslušajte veter, zvok tišine. Poslušajte zvoke, kot da vam je zunanji svet nekaj tujega.</p>' +
                '<p>Nato se spet nekaj minut zavedajte samo procesa dihanja. Ne zaznavajte zunanjega sveta ampak samo tok dihanja. Naj bo vsa vaša pozornost usmerjena k dihanju. In spet po nekem času spet usmerite svojo pozornost k zunanjim zvokom. Tako nadaljujte vse dokler imate čas.</p>',

            uvodDva:
                '<p>Sedaj vadite stopnjo 1; to je, da se zavedate zunanjih zvokov. Nato po nekaj minutah nadaljujte na stopnjo 2. Svojo pozornost usmerite v miselni proces uma; pozabite na zunanji svet. Ne izbirajte misli; naj nastanejo spontano. Naj se vse misli pojavijo svobodno brez najmanjših omejitev. Ne izbirajte prijetnih misli v primerjavi z neprijetnimi mislimi - sprejmite vse, kar pride. Nekatere misli lahko sledijo vzorcu, druge se lahko pojavijo in nimajo očitne povezave s prejšnjimi mislimi. Morda razmišljate o delu, morda razmišljate o hrani, lahko razmišljate o sovražniku ali pa bi si želeli, da bi lahko spali. Za te misli niso potrebni nobeni zunanji dražljaji; izhajajo iz površinskih ali globljih plasti podzavesti.</p>' +
                '<p>Ostanite pozorni in budni vseskozi. Zavedajte se; samo opazujete misli, kot da se pojavljajo zunaj vas. Nenehno se spominjajte, da so te misli ločene od vas. Redno si govorite: "Drugačen sem od teh misli." ali pa "Te misli nisem Jaz." Vsake toliko časa se vprašajte: "Ali se zavedam teh misli?" Ne kontrolirajte nobene misli; samo opazujte jih. Dovolite vašemu umu, da misli kaj hoče; ne vmešavajte se v to. Bodite samo priča; poskusite biti nepovezan opazovalec vseh misli. Včasih bodo misli zaželene ali pa nezaželene - včasih tečejo kot mogočna reka. Včasih bo prišlo do zatiranja misli. Sama opazujte. Ne nasprotujte nobeni misli. Dovolite, da vsa čustva, kot so sovraštvo, strah, krivda in tako naprej, nastanejo brez odpora. Izpustite vsa ta stisnjena čustva. Ampak poskusite ostati priča, kot videc, ločeni od čustev in misli. Bodite pozorni in sčasoma se vam bodo pokazali zatirani deli osebnosti. Združite budnost s svobodo misli. Sprostite vse ustekleničene občutke in misli, ki so vam v preteklosti povzročile toliko bolečine in trpljenja - pustite, da se pojavijo in izhlapijo. Odprite vrata podzavesti. Poglejte v notranjost in odstranite vse neuporabne duševne odpadke, ki se tam nahajajo.</p>' +
                '<p>Nadaljujte z gledanjem, opazovanjem svojih misli. Potem po petih minutah opazujte umni zaslon pred zaprtimi očmi. To je zaslon vašega uma, na katerem je mogoče videti podzavestne vizije. Pazljivo si oglejte zaslon. Ne ustvarjajte ničesar in ne pričakujte ničesar. Samo glejte. Če se pojavijo vizije, jih le opazujte kot priča. Če se vizije ne pojavijo, ne skrbite ... še naprej bodite pozorni na ta umski zaslon z budnostjo, vendar brez pričakovanj. Po nekaj minutah nadaljujte z zavedanjem o miselnem procesu. Oglejte si vse misli, ki izhajajo iz nezavednega. Po nekaj časa se spet vrnemo k opazovanju umnega zaslona pred zaprtimi očmi. Ne bodite napeti ... samo opazujte. Ali se vizije pojavijo ali ne, ne skrbite, samo opazujte. Nato se spet vrnite v odmaknjeno opazovanje miselnega procesa. Na ta način nadaljujte tako dolgo, dokler imate na voljo dovolj časa.</p>',

            uvodGlavni: '<p>Nad seboj vidite nadangela Gabriela, ki vas gleda in vas hkrati vabi vam hkrati tudi pošilja svetlobo in ta svetloba vas prenavlja od znotraj in zunaj in vam daje moč in voljo, ki jo potrebujete, ko se podajate na pot do višjega obstoja. Recite si in to tudi občutite: "Sem novo bitje in vse moje misli, občutki, dejanja in spoznanja so prenovljeni. Sem otrok božanskega ognja, iskra iz pra-plamena, ki daje moč in energijo vsemu obstoju. Zemlja me ne more zadušiti in zadržati; voda me ne more utopiti in zadržati." Sedaj počasi, mirno in globoko nekajkrat vdihnite in čutite kako svetloba iz vrha napaja vaše celotno bitje in se z vsakim vdihom vse bolj in bolj širi in si recite: "Naj me dih mojega Jaza ponese v novo življenje in novo dejavnost." Čutite kako ste z vsakim vdihom prenovljeni. Recite si z vsakim vdihom: "Prenovljen/a sem. Odločam se, da se bom vsak dan obnavljal.</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega dihanja. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu pa se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

            dodatno: '<ol><li>>Vsak dan si vzemite čas za 30 zavestnih dihov. Vdih in izdih je en dih. Tudi preden zaspite se lahko zavestno ostredotočate na trebušno dihanje in se na ta način sproščate.</li>' +
                '<li>Vsako jutro, ko se zbudite, si recite, da bo to dan v katerem bo več zavedanja, kot pa dan pred njim. V ta namen si lahko pripravite kakšen listek ali nekaj, kar vas opominja, da živite bolj zavedno. Lahko uporabljate tudi kakšno aplikacijo (na primer "<i>Mindfulness Bell</i>") na telefonu, ki vas bo naključno opominjala, da postanete zavedni.</li></ol>',

            opombe: '<ol><li>Če imate čas in voljo, si lahko te različne zvoke posnamete in posnetek uporabljate pri sadhani.</li>'

        }
    }
}
