<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Sadhana
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Sadhana</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid pb-4 p-0 bg-tarot">
    <div class="container p-3">

        <div class="py-4 text-center">
            <h1>Sadhana</h1>
            <div class="lead text-center">"Ne meditiraj... BODI! Ne misli, da si... BODI! Ne razmišljaj o tem, da si...
                TI SI!"
            </div>
            <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
        </div>

        <span v-for="(prikaz, index) in sadhane" :key="index">
            <a class="text-decoration-none text-white" :href="prikaz.ide">{{ prikaz.ime }}</a> |
        </span>

        <div class="py-4 text-center">
            <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
        </div>
        <div class="row row-cols-1 row-cols-xl-1 g-4">

            <div class="col pt-2" v-for="(sadhana, index) in sadhane" :key="index" :id="sadhana.id">
                <div class="card bg-transparent border-0">
                    <div class="row g-0">
                        <div class="col-lg-3 pe-2 pe-lg-1 d-flex align-items-center justify-content-center">
                            <router-link :to="`${sadhana.toOpis}`">
                                <img :src="sadhana.img" class="img-fluid rounded-start shadow" :alt="sadhana.ime">
                            </router-link>
                        </div>
                        <div class="col-lg-9 mt-3 bg-dark shadow-card border-dark rounded-0 bg-image-cards bg-opacity-75 text-white">
                            <h3 class="card-header text-center border-0 bg-transparent">
                                {{ sadhana.ime }}<br>
                                <span class="text-warning fs-2" v-html="sadhana.hLetter"></span><br>
                                {{ sadhana.pot }}<br>

                            </h3>
                            <div class="card-body">
                                <p class="card-text text-center" v-html="sadhana.opis"></p>
                                <p class="card-text text-end" v-show="sadhana.cas !== false">
                                    <small class="text-white-50">Minimalni čas tukaj: {{ sadhana.cas }} meseca</small>
                                </p>
                            </div>
                            <div class="d-grid gap-2">
                                <router-link
                                        class="btn btn-warning card-footer border-dark rounded-0 bg-tarot fs-5 fw-bold"
                                        :to="`${sadhana.to}`"> Naprej na sadhano
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center d-lg-none">
                    <img src="~@/assets/hrline.png" class="img-fluid text-center pt-3" alt="">
                </div>
            </div>
        </div>

    </div>
</div>
