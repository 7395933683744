<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Kabala
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Kabala</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot">
    <div class="container p-3">

        <div class="py-4 text-center">
            <h1>Kabala</h1>
            <div class="lead text-center">{{ kabalaRek }}</div>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>

        <div class="row">
            <div class="col-lg-6 p-3 d-flex align-items-center justify-content-center">
                <img src="~@/assets/kabala/kabala.png" alt="Kabala" class="img-fluid">
            </div>
            <div class="col-lg-6 p-3">

                <div class="row pb-2" v-for="(opis, index) in opisi" :key="index">
                    <div class="col-lg-12 pb-3">
                        <div class="card border-dark rounded-0 shadow-card bg-dark bg-image-cards bg-opacity-75 text-white">
                            <div class="row g-0">
                                <div class="col-lg-12">
                                    <h2 class="card-header border-0 bg-transparent">{{ opis.naslov }}</h2>
                                    <div class="card-body">
                                        <p class="card-text" v-html="opis.opis">

                                        </p>
                                    </div>
                                    <div class="d-grid gap-2">
                                        <router-link
                                                class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                                                :to="opis.to">
                                            Beri dalje
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>
