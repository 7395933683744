<div class="container-fluid p-0 shadow-sm">
    <footer class="bg-dark bg-gradient text-white text-center text-md-start">
        <div class="p-4">
            <div class="row">
                <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Tarot</h5>

                    <p>
                        Ko opustite idejo o napovedovanju prihodnosti, začnete karte doživljati kot ogledalo psihe.
                        Takrat delanje s tarotom postane pot do modrosti. In takrat se tudi začne resnično delo na vas
                        samih in takrat vsak posameznik spozna, da je ključ do svobode sprejemanje vsega takšno kakršno
                        je.
                    </p>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Samopomoč</h5>

                    <ul class="list-unstyled">
                        <li>
                            <a @click="showModalSlike = true" class="text-white cursor">Slike</a>

                            <vue-final-modal v-model="showModalSlike"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalSlike = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Slike</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/crno-bele-karte-velika-arkana.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>

                        </li>
                        <li>
                            <a @click="showModalPomoc= true" class="text-white cursor">Dnevnik</a>
                            <vue-final-modal v-model="showModalPomoc"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalPomoc = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Delovni zvezek za samopomoč</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/dnevnik-samopomoc.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>

                        </li>
                        <li>
                            <a @click="showModalOdpuscanje= true" class="text-white cursor">Odpuščanje</a>
                            <vue-final-modal v-model="showModalOdpuscanje"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalOdpuscanje = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Odpuščanje vas osvobodi</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/odpuscanje.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>
                        </li>
                        <li>
                            <a @click="showModalZivljenje= true" class="text-white cursor">Življenje</a>
                            <vue-final-modal v-model="showModalZivljenje"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalZivljenje = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Slava življenja</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/slavite_svoje_zivljenje.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                    <h5 class="text-uppercase">TAROT</h5>

                    <ul class="list-unstyled">
                        <li>
                            <a @click="showModalTarot= true" class="text-white cursor">Tarot</a>
                            <vue-final-modal v-model="showModalTarot"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalTarot = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Ključne besede tarot kart (prazen)</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/keywordsofthecards.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>
                        </li>
                        <li>
                            <a @click="showModalTarotUcenje= true" class="text-white cursor">Učenje</a>
                            <vue-final-modal v-model="showModalTarotUcenje"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalTarotUcenje = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Ključne besede tarot kart (prazen)</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/tarot_ucenje.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>
                        </li>
                        <li>
                            <a @click="showModalFearless= true" class="text-white cursor">Knjiga ANG</a>
                            <vue-final-modal v-model="showModalFearless"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalFearless = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Sanjati je božansko</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/fearless_tarot.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>
                        </li>
                        <li>
                            <a @click="showModalNasveti= true" class="text-white cursor">Nasveti</a>
                            <vue-final-modal v-model="showModalNasveti"
                                             classes="modal-container-three animate__animated animate__bounceInDown"
                                             content-class="modal-content">
                                <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                                        @click="showModalNasveti = false">
                                    <mdi-close class="fw-bold text-white">X</mdi-close>
                                </button>
                                <span class="modal__title text-center text-dark py-3">Nasveti za začetnike</span>

                                <div class="modal__content">
                                    <div class="container">
                                        <iframe src="https://tarot-zate.si/prenos/nasveti.pdf"
                                                style="min-width: 100%; min-height: 65vh"></iframe>
                                    </div>
                                </div>
                            </vue-final-modal>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
            © {{ thisYear() }} Copyright:
            <a class="text-white" href="https://www.tarot-zate.si/">tarot-zate.si</a>
        </div>
    </footer>
</div>
