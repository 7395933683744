<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Tarot
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Tarot</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-header">
    <div class="container p-md-4 p-2">

        <div class="py-4 text-center">
            <h1>Tarot</h1>
            <p class="lead text-center" v-html="modrost"></p>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>
        <div class="row row-cols-1 row-cols-xl-5 g-4">
            <div class="col-xl d-flex align-items-center justify-content-center" v-for="(arkana, index) in arkane"
                 :key="index">
                <router-link :to="`${arkana.to}`" class="text-decoration-none">
                    <div class="card-my">
                        <div class="content-my">
                            <div class="front">
                                <img :src="arkana.image" class="img-fluid shadow">
                            </div>
                            <div class="back text-white bg-dark shadow bg-image-cards bg-opacity-75">
                                <h3 class="py-5 text-shadow" v-html="arkana.opis"></h3>
                            </div>

                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="pt-4 text-center">
        <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>
    </div>

    <div class="container p-md-4 p-2">
        <p v-html="opis"></p>
    </div>

</div>
