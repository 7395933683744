<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Tarot vaje
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Tarot vaje</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot pb-3">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>{{ naslov }}</h1>
            <div class="lead text-center">{{ rek }}</div>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>
        <p v-html="opisVaje"></p>

        <div class="row row-cols-1 row-cols-xl-2 g-4 pt-3">
            <div class="col" v-for="(vaja, index) in vaje" :key="index">
                <div class="card text-white h-100 border-dark rounded-0 bg-dark shadow-card bg-image-cards bg-opacity-75">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ vaja.naslov }}</h3>
                    <div class="text-center">
                        <img src="~@/assets/hrline.png" class="img-fluid" style="max-width: 70%;">
                    </div>
                    <div class="card-body">
                        <p class="card-text">{{ vaja.opis }}
                            <a class="text-decoration-none text-warning cursor"
                               @click="vaja.modalShow = true">
                                {{ vaja.show === true ? vaja.prenos : '' }}
                            </a>
                        </p>
                    </div>
                </div>
                <!-- MODAL DOWNLOAD START -->
                <vue-final-modal v-model="vaja.modalShow"
                                 classes="modal-container-three animate__animated animate__bounceInDown"
                                 content-class="modal-content">
                    <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                            @click="vaja.modalShow = false">
                        <mdi-close class="fw-bold text-white">X</mdi-close>
                    </button>
                    <span class="modal__title text-center text-dark py-3">Meditacija na tarot karte</span>
                    <div class="modal__content">
                        <div class="container">
                            <iframe src="https://tarot-zate.si/prenos/meditacija_na_tarot.pdf"
                                    style="min-width: 100%; min-height: 65vh"></iframe>
                        </div>
                    </div>
                </vue-final-modal>
            </div>
        </div>

    </div>
</div>
