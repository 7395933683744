<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Meditacija blisk strele
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/kabala">Kabala</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Meditacija blisk strele</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>Meditacija blisk strele</h1>
            <div class="lead text-center">{{ kabalaRek }}</div>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>
        <!-- SLIKA VIDNA NAD lg-->
        <img src="~@/assets/kabala/kabala-med.png" alt="Kamala meditacija" ondragstart="return false;"
             class="float-lg-start img-fluid m-2 d-none d-lg-block">
        <!-- SLIKA VIDNA POD lg-->
        <div class="d-flex py-md-0 py-4 align-items-center justify-content-center">
            <img src="~@/assets/kabala/kabala-med.png" ondragstart="return false;"
                 class="p-md-4 p-0 d-lg-none img-fluid">
        </div>
        <div v-html="meditacija"></div>
    </div>
</div>
