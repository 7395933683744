export default {
    data() {
        return {
            naslov: "Sadhana 0 - Norec",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            jutranja: '<p>Ko se zbudite, si zapišete sanje. Potem se ritualno umijete, naredite Jutranjo molitev Kurukulli in nato naredite Surya namaskaro 5 ciklov. Nato se usedete, naredite Viparet karani mudro in ko končate naredite 11x Nadi shodhana pranayama 3. Temu sledi sadhana, kjer najprej naredite meditacijo na čiščenje elementov, temu takoj sledi tattwa shuddhi, nato čakra sadhana. Temu sledi Guru mantra in nato 11x ponovitev mantre <b>OM GAM SWAHA</b> nato meditacija umiranja in ponovnega rojstva in temu sledi 32 imen Durge in Durga mantra 108x. Temu nato sledi 108x dolga Gayatri mantra in potem Kurukulla mantra. Za konec pa Meditacija Norca. Nato končate jutranjo sadhano.</p>',

            vecerna: '<p>Najprej umirite telo z Čandra namaskaro in nato sledi Mentalna menjava diha in večerna molitev Kurukuli. Temu spet sledi sadhana, kjer najprej naredite meditacijo na čiščenje elementov, temu takoj sledi tattwa shuddhi, nato čakra sadhana. Temu sledi Guru mantra in nato 11x ponovitev mantre <b>OM GAM SWAHA</b> nato meditacija umiranja in ponovnega rojstva in temu sledi Durga mantra 108x. Temu nato sledi 108x Mahamrityunjay mantra in potem Kurukulla mantra. Za konec pa Meditacija Norca. Nato končate večerno sadhano.</p>',

            meditacijaNorca: '<p>Meditirajte na naslednje: "V meni je prisotna vzvišena čistost neizrekljivega bistva. Prepoznavam se kot bistvo brez oblike, brez lastnosti in brez omejitve. Sem vse v vsem. Jaz nisem nič, ampak sem prisoten v vseh stvareh. Jaz sem končna točka vsega in primarni izvor začetka. Bil sem in nisem bil; sem in nisem; bom, vendar sem popolnoma brez prihodnosti. Jaz sem krog s središčem povsod in brez obsega. Kot otrok in starodaven, mlad in starejši, nov in star. Čistejši od snega, bolj sijoč od sonca, manjši od najmanjšega atoma, večji od prostranstva vesolja. Vse to sem. Jaz sem vse in nič. sem svoboden. Torej sem, sem bil in bom, znotraj biti in v nebivanju, znotraj časa in izven časa, vedno in nikoli. Om!"</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

        }
    }
}
