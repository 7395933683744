<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Literatura
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Literatura</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>Literatura Tarota</h1>
            <div class="lead text-center">"Tisti, ki bere živi tisoč življenj, preden umre... Ta, ki nikoli ne bere,
                živi samo eno."
            </div>
            <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
        </div>

        <div class="row row-cols-1 row-cols-lg-2 g-4">

            <div class="col" v-for="(literatura, index) in knjige" :key="index">
                <div class="card shadow-card bg-image-cards bg-opacity-75 text-white bg-dark rounded-0 mb-3 position-relative">
                    <a :href="literatura.depository" target="_blank">
                        <img src="~@/assets/buy.png" class="position-absolute bottom-0 end-0 p-2">
                    </a>
                    <div class="row g-0">
                        <div class="col-lg-4">
                            <img :src="literatura.image" class="img-fluid h-100" style="min-width: 100%"
                                 :alt="literatura.naslovKnjige">
                        </div>
                        <div class="col-lg-8">
                            <div class="card-body">
                                <h4 class="card-title">{{ literatura.naslovKnjige.toUpperCase() }}</h4>
                                <h6 class="card-title fst-italic">{{ literatura.podnaslov }}</h6>
                                <p class="card-text">{{ literatura.opis }}</p>
                                <p class="card-text fs-6 fst-italic">Avtor: {{ literatura.avtor }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="py-4 text-center">

            <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
            <div class="lead text-center">"Knjige so kot tarot karte. Zagotavljajo odgovore in smernice, a kar je še
                pomembneje, so vrata in portali v onstranstvo in domišljijo. Pustijo svoj odtis in nam šepetajo še dolgo
                potem, ko zapremo strani ali premešamo karte."
            </div>
        </div>

    </div>
</div>
