<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            404
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">404</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot">
    <div class="container text-center p-3">
        <div class="py-4 text-center">
            <h2>Ups, stran ni bila najdena ali ne obstaja!</h2>
            <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
        </div>
        <img src="~@/assets/majorarcana/the-tower.png" class="img-fluid">
        <div class="py-4 text-center">
            <img src="~@/assets/hrline.png" class="img-fluid text-center" alt="">
        </div>
    </div>
</div>
