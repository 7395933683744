<div class="container-fluid bg-tarot-index text-white shadow-lg">
    <div class="container p-4">
        <div class="fs-1 fw-bold" v-for="karta in modrost">
            {{ karta.tarotKarta }}
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/tarot">Tarot</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/meci">Mala arkana: Meči</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page" v-for="karta in modrost">
                    {{ karta.tarotKarta }}
                </li>
            </ol>
        </nav>
    </div>
</div>
<div class="container-fluid p-0 pt-5 bg-tarot">
    <div class="container text-black">
        <div class="row p-0 d-flex align-items-center justify-content-center"
             v-for="(modrosti, index) in modrost" :key="index"
        >

            <!-- SREDINA -->
            <div class="col-lg-4 p-3 text-center">
                <img src="~@/assets/swords/seven-of-swords.png" alt="Tarot karta Sedmica mečev" ondragstart="return false;"
                     style="height: 500px" class="img-fluid shadow">
            </div>

            <!-- DESNA nad lg-->
            <div class="col-lg-4 fs-5 p-3 fw-bold d-none d-lg-block" v-html="modrosti.rekDesno">
            </div>

            <!-- DESNA pod lg-->
            <div class="col-lg-4 fs-5 p-3 fw-bold text-center d-lg-none" v-html="modrosti.rekDesno">
            </div>

        </div>
    </div>

    <div v-for="(opis, index) in opisKarte" :key="index">

        <!-- SPODAJ -->
        <div class="container">
            <div v-html="opis.opisKarte" class="p-2"></div>
        </div>


        <div class="container py-4 py-md-5 p-2 p-md-4 shadow bg-image-cards bg-dark text-white bg-opacity-75">
            <!-- PODNASLOV POKONČNA -->
            <div class="text-center ">
                <h1 class="shadow-text-main">{{ opis.podnaslovPokoncna }}</h1>
                <img src="~@/assets/hrline.png" class="img-fluid text-center pb-0 pb-md-3">
            </div>
            <!-- SLIKA VIDNA NAD lg-->
            <img src="~@/assets/swords/seven-of-swords.png" alt="Tarot karta Sedmica mečev" ondragstart="return false;"
                 class="float-md-start m-2 d-none d-lg-block shadow">

            <!-- SLIKA VIDNA POD lg-->
            <div class="d-flex py-md-0 py-4 align-items-center justify-content-center">
                <img src="~@/assets/swords/seven-of-swords.png" alt="Tarot karta Sedmica mečev" ondragstart="return false;"
                     class="p-md-4 p-0 d-lg-none shadow">
            </div>

            <div v-html="opis.opisPokoncna"></div>
            <span class="fw-bold">Ključne besede pokončne karte:</span> {{ opis.besedePokoncna }}
        </div>


        <div class="container shadow py-4 py-md-5 p-2 p-md-4">
            <!-- PODNASLOV OBRNJENA -->
            <div class="text-center">
                <h1 class="shadow-text-main">{{ opis.podnaslovObrnjena }}</h1>
                <img src="~@/assets/hrline.png" class="img-fluid text-center pb-3">
            </div>

            <div v-html="opis.opisObrnjena"></div>
            <span class="fw-bold">Ključne besede obrnjene karte:</span> {{ opis.besedeObrnjena }}
        </div>

        <div class="container py-4 py-md-5 p-2 p-md-4 shadow bg-image-cards bg-dark bg-opacity-75">
            <!-- PODNASLOV -->
            <div class="text-center">
                <h1 class="shadow-text-main">Pomen simbolov v karti</h1>
                <img src="~@/assets/hrline.png" class="img-fluid text-center pb-3">
            </div>
            <!-- OPIS VIDEN SAMO NA NAPRAVAH NAD lg -->
            <div class="d-none d-lg-block">
                <div class="row">
                    <div class="col-lg-9">
                        <ul class="list-group list-group-numbered rounded-0 border border-dark bg-dark bg-gradient shadow">
                            <li class="list-group-item bg-transparent text-white border-0 d-flex justify-content-between align-items-start"
                                v-for="(simbol, index) in opisSimbolov" :key="index">
                                <div class="ms-2 me-auto">
                                    <div class="fw-bold">{{ simbol.simbol }}</div>
                                    {{ simbol.opis }}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <div class="text-center pb-3">
                            <img src="~@/assets/swords/seven-of-swords.png" alt="Tarot karta Sedmica mečev" class="shadow" ondragstart="return false;">
                        </div>
                        <ul class="list-group list-group-flush border border-dark">
                            <li class="list-group-item text-white shadow border-0 bg-opis" v-for="element in elementi">
                                <b>{{ element.ime }}</b>
                                {{ element.vrednost }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- OPIS VIDEN SAMO NA NAPRAVAH POD lg -->
            <div class="d-lg-none">
                <div class="text-center pb-3">
                    <img src="~@/assets/swords/seven-of-swords.png" alt="Tarot karta Sedmica mečev" ondragstart="return false;" class="shadow">
                </div>
                <ul class="list-group list-group-numbered rounded-0 border border-dark bg-dark bg-gradient shadow mb-4">
                    <li class="list-group-item text-white bg-transparent border-0 d-flex justify-content-between align-items-start"
                        v-for="(simbol, index) in opisSimbolov" :key="index">
                        <div class="ms-2 me-auto">
                            <div class="fw-bold">{{ simbol.simbol }}</div>
                            {{ simbol.opis }}
                        </div>
                    </li>
                </ul>
            </div>

            <!-- OPIS VIDEN SAMO NA NAPRAVAH POD lg -->
            <div class="d-lg-none">
                <ul class="list-group list-group-flush border border-dark mb-4">
                    <li class="list-group-item shadow bg-opis text-white border-0" v-for="element in elementi">
                        <b>{{ element.ime }}</b>
                        {{ element.vrednost }}
                    </li>
                </ul>
            </div>
        </div>

        <div class="container shadow py-4 py-md-5 p-2 p-md-4">
            <!-- PODNASLOV -->
            <div class="text-center">
                <h1 class="shadow-text-main">{{ opis.podnaslovAfirmacija }}</h1>
                <img src="~@/assets/hrline.png" class="img-fluid text-center pb-3">
            </div>
            <div class="text-center fs-4" v-html="opis.afirmacija"></div>
        </div>

    </div>
</div>
