<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Admin
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Admin</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot position-relative pb-3">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>Admin dashboard</h1>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>

        <div class="container">
            <div class="row row-cols-1 row-cols-xl-2 g-4 pt-3">
                <div class="col text-center">
                    <router-link to="/objavi" class="text-decoration-none">
                        <div class="card text-white h-100 border-dark rounded-0 bg-dark shadow-card bg-image-cards bg-opacity-75">
                            <h3 class="card-header border-0 bg-transparent text-center">Objavi</h3>
                            <div class="text-center">
                                <img src="~@/assets/hrline.png" class="img-fluid" style="max-width: 70%;">
                            </div>
                            <div class="card-body">
                                Objavi novo objavo!
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col text-center">
                    <router-link to="/pregled" class="text-decoration-none">
                        <div class="card text-white h-100 border-dark rounded-0 bg-dark shadow-card bg-image-cards bg-opacity-75">
                            <h3 class="card-header border-0 bg-transparent text-center">Pregled</h3>
                            <div class="text-center">
                                <img src="~@/assets/hrline.png" class="img-fluid" style="max-width: 70%;">
                            </div>
                            <div class="card-body">
                                Pregled, izbris ali urejanje vseh objav v blogu!
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>


        </div>

    </div>
</div>

