export default {
    data() {
        return {
            naslov: "Sadhana XIV – Zmernost",

            priprava:
                '<p>Usedite se v udoben meditacijski položaj s prekrižanimi nogami, sede na stolu ali pa kako drugače. Poskrbite, da boste lahko ves čas meditacije sedeli udobno in brez kakršnih koli večjih neprijetnosti. Zaprite oči roke položite na kolena in se začnite zavedati vsega telesa. Zavedajte se vsega telesa, položaja rok in nog, zavedajte se hrbtenice ki podpira glavo in zavedajte se vašega stika s tlemi. Nadaljujte s tem nekaj trenutkov.</p>' +
                '<p>Nato se pričnite zavedati vseh občutkov v telesu. Zavedajte se hladu, toplote, vetra, bolečine, togosti, neudobja, srbenja. Zavedajte se vseh teh občutkov v telesu. Ne premikajte se le zavedajte se tega nekaj trenutkov. Nato se zavedajte samo glave in vseh občutkov v glavi in ničesar drugega. Nato nadaljujte k vratu. Nadaljujte k desni roki in desni nogi. Nato k levi roki in nadaljujte k levi nogi. Zavedajte se obeh rok in obeh nog. Nato nadaljujte k zadnjemu delu trupa in nato k prsim, nato trebuh. In končno celotno telo. Sprostite se. Lahko naredite še eno rundo <sup class="fw-bold">*<sup> 1</sup></sup>.</p>' +
                '<p>Nato se pričnite zavedati svojega diha in telesa hkrati. Ne spreminjajte diha samo zavedajte se naravnega toka diha. Zavedajte se, kako se z vsakim dihom vaše telo vse bolj sveti. Predstavljajte si telo kot svetlobo, ki z vsakim dihom vse bolj sveti in se dotika vsega okoli vas. Ta svetloba gre z vsakim dihom dlje in dlje, vse dokler ne prežame celoten svet in celo ves naš svet žari v beli svetlobi.</p>',

            meditacijaSwadisthana:
                '<p>Pričnite se zavedati, da sedite na belem krogu. V krogu pa je polmesec srebrne barve. Krog ima ravno tolikšen premer, da lahko na njem sedite. Krog ima 6 lotosovih cvetov, ki so temno rdeče barve. Počutite se popolnoma pomirjeno. Ste pomirjeni in prizemljeni. Medtem ko sedite čutite vse smeri neba vendar tej prostranosti okoli vas ni konca. Je brezmejni prostor. Pomembno je, da to čutite.</p>' +
                '<p>Po nekaj trenutkih pričnite čutiti po vrstnem redu naslednje:</p>' +
                '<ol>' +
                '<li>Zavedajte se morebitne lenosti - po nekaj trenutkih 3x ponovite mantro <b>BAM</b> in čutite, kako se lenost transformira v energičnost in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Zavedajte se svoje sumničevosti - po nekaj trenutkih 3x ponovite mantro <b>BHAM</b> in čutite kako se sumničevost transformira v nevtralnost in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Zavedajte se kakršnega koli prezira, ki ga imate - po nekaj trenutkih 3x ponovite mantro <b>MAM</b> in čutite kako se prezir transformira v sočutje in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Zavedajte se kakršne koli svoje zablode - po nekaj trenutkih 3x ponovite mantro <b>YAM</b> in čutite kako se zabloda transformira v iskrenost in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Zavedajte se kakršne koli destruktivnosti sebe ali drugih - po nekaj trenutkih 3x ponovite mantro <b>RAM</b> in čutite kako se destruktivnost transformira v sprejemanje in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Zavedajte se neusmiljenosti, če je kje - po nekaj trenutkih 3x ponovite mantro <b>LAM</b> in čutite kako se neusmiljenost transformira v usmiljenost in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '<li>Zavedajte se swadhisthana čakre in ponovite mantro <b>VAM</b> in čutite brezmejno vibracijo, ki se širi v vse smeri.</li>' +
                '</ol>' +
                '<p>Okoli kroga je šest lotosovih cvetov temno rdeče barve in svetijo v vse smeri neba. So prelepe krvave barve, ki vam prinašajo sočutje, energijo, mirnost, voljo. Kmalu se pod vami pojavi še voda, nad kateri lebdite z lotosom pod sabo.</p>' +
                '<p>Sedaj vidite celotno sliko čakre in ponovite naslednje mantre še enkrat: <b>BAM</b> (in vizualizirajte prvi cvet čakre kako še bolj sveti in pojdite naprej v smeri urinega kazalca), <b>BHAM</b>, <b>MAM</b>, <b>YAM</b>, <b>RAM</b>, <b>LAM</b> in ponovno <b>VAM</b> 11x in čutite, kako postanete čista svetloba. Če poznate lahko tukaj vadite muladhara bando in jalandara bandho</p>',

            mentalnaMenjava: '<p>Čiščenje levega nadija (Ida): Predstavljajte si in občutite dih, ki vstopa in izhaja skozi levo nosnico. Sinhronizirajte mantro <b>HAM</b> z vdihom in izdihom. Nadaljujte približno 5 minut; ali če želite, lahko preštejete število vdihov od 108 do 0, od 54 do 0 ali od 27 do 0.</p><p>Čiščenje desnega nadija (Pingala): poveča pretok diha skozi desno nosnico. Vse podrobnosti so enake kot za levi nadi, le da si moramo predstavljati, da dih teče skozi desno nosnico namesto skozi levo nosnico in mantra je <b>KŠAM</b>.</p><p>Obe tehniki se uporabita za povečanje pretoka dihanja v eni nosnici, da se doseže ravnovesje. To prakso izvajamo pred izvajanjem naslednje prakse, da bi povečali občutljivost na pretok diha skozi nosnice.</p>' +
                '<p>Sledeča tehnika pa je odlična metoda, ki jo običajno uvrščamo med tehnike meditacije. Z mentalno sugestijo prinaša ravnovesje ide in pingale. Predstavljajte si, da dih teče izmenično skozi obe nosnici. Najprej vdih skozi levo in nato izdih skozi desno in nato vdih skozi desno in izdih skozi levo. To je ena runda, naredite jih 11, 27, 54 ali 108. Sprva bodo vdihi verjetno neenaki, čez nekaj časa pa bosta miselna sugestija in domišljija prinesli fizično spremembo. Hkrati bo praksa povzročila globoka stanja sproščenosti, vendar brez izgube v toku misli in psihičnih slik. To je preprosta praksa, ki ima opazno lastnost v primerjavi z večino drugih meditativnih tehnik; medtem ko povzroča sprostitev, hkrati vzpostavlja ravnovesje med ido in pingalo, tako da se človek ne izgubi v mislih ali spanju. Sama mehanika vadbe je takšna, da se ida in pingala samodejno uravnovesita.</p><p> Kasneje lahko vdihu in izdihu skozi levo nosnico dodate mantro <b>HAM</b> izdihu in vdihu desne pa <b>KŠAM</b>.</p><p>Ko se boste te prakse resnično navadli, pa lahko dodate še notranje zadrževanje diha med vdihom in izdihom in ponavljanje mantre <b>OM<b> nekaj sekund.</p>',

            gayatriMantra:
                '<p>Občutite zlate sončne žarke, ki se širijo skozi vaše telo in um.</p><p>Sedaj si predstavljajte zlato, soncu podobno kroglo pred seboj in prinesite to zlato svetlobo vase. Lahko si pred seboj, nekje nad hribom predstavljate sonce. Pustite, da vstopi v sredino obrvi, nato pa naj ta svetloba počasi potuje navzdol do predela na sredini prsnega koša. Tam občutite zlate sončne žarke, ki se širijo skozi vaše telo in um.</p><p>Ponovite mantro 3x: <b>Om Aim Gurubhyo Namah</b>. Nato si predstavljajte, da sonce v vas sije vse bolj in bolj. Predstavljajte si kako je sonce v vas, nad vašo glavo in pred vami in recitirajte mantro Gayatri.</p><p>Ponovite mantro tolikokrat, kot se zdi naravno. Za daljšo vadbo lahko uporabite malo (komplet 108 krobljic za štetje mantre). Naj zvok odmeva v vas. Čutite vibravcije in svetlobo, ki se širi. Naj zapolni ves prostor vaše notranjosti in zunanjosti. Naj tej svetlobi ne bo konca.</p><p>S časom in prakso bo ritem vsakodnevne vadbe Gayatri osvetlil vaša jutra in večere s tiho radostjo. Mantra vas bo povzdignila nad nemirna mesta in povrnila duhovno zaupanje. Tiha vsakodnevna praksa in dvig - to so potrebe duše. Oboje boste našli v mantri Gayatri.</p>' +
                '<p class="text-center">oṃ bhūr bhuvaḥ svaḥ<br>tat savitur vareṇyaṃ<br>bhargo devasya dhīmahi<br>dhiyo yo naḥ pracodayāt</p>' +
                '<p><b>Pomen v celoti:</b> <i>"Om. V vseh treh ravneh obstoja se v sebi spominjamo in meditiramo na ta čudoviti Duh božanskega Sončnega Bitja; naj vodi naš notranji vid."</i></p>' +
                '<p><b>Om</b> končna resničnost.<br><b>bhur</b> raven petih elementov; zadeva.<br><b>bhuvah</b> raven življenjske sile ali prane;<br><b>svah</b> raven uma; nebesa<br><b>tat</b> ta.<br><b>savitur</b> Sončnega Bitja, sončna krogla, svetloba in energija sonca, ki nas žene k rasti in spoznavanju Božanskosti.<br><b>varenyam</b> čudovit, lep, vreden izbire, pre-čudovit.<br><b>bhargo</b> duh, ki prebiva v soncu; luč znanja, ki raztopi strah in nevednost.<br><b>devasya</b> božanskega, briljantnega, sijočega.<br><b>dhimahi</b> meditiramo, razmišljamo, se spominjamo, prikličemo v spomin.<br><b>dhiyo</b> misli (vritti), intelekt (buddhi), notranji vid.<br><b>yo</b> kdor<br><b>nah</b> naš<br><b>prachodayat</b> naj nas vodi, usmerja.</p>',

            meditacijaZmernosti: '<p>Predstavljajte si da ste prirpavljeni na vse, kar je namenjeno proti vam. Ponovite si naslednje: "Vstopam v ravnovesje, v palačo moje veličastne brezmejnosti. Kličem pogum in iskrenost, da mi pomagata pri prehodu skozi zlata vrata brezmejnosti. Ponižno se preklanjam pred lepoto Večnosti,  ki kraljuje iz središča mojega bitja." Predstavljajte si brezmejni prostor, predstavljajte si brezmejnost in neskončnost. Predstavljajte si kako v tej brezmejnosti zasvetite v veličastni svetlobi, ste prelepi in čisti in si recite: "Razmišljam o veličastni lepoti srca vseh bitij.  Naj se moj um zaveda svoje veličastnosti in moje srce obžari ljubezen. Usmerjam svetlobo v svoje telo in dušo, da bo svetloba moje volje usmerjena v najvišji cilj." Čutite kako se svetloba dotika vsega in vseh in si recite: "Svetloba leti v srce vseh! Naj ostaneta svetloba in njen cilj za vedno združena v lepoti in ljubezni."</p>',

            konec: '<p>Ko ste pripravljeni, da končate se zavedajte svojega telesa in vseh prijetnih občutkov. Predstavljajte si, kako se počasi pričenjate vračati v prostor od koder ste prišli. Zavedajte se, da se vračate nazaj. Naj bo ta proces počasen. Pričnite se zavedati dihanja in kako pri vdihu vdihnete svetlobo, ki napolni vaše telo. Pri izdihu se vedno bolj zavedate svojega telesa. Zavedajte se diha in telesa hkrati. Naj bo ta proces vračanja ali zaključka počasen, ne hitite. Vzemite si čas, da začutite telo in dih. Po nekaj trenutkih se zavedajte položaja telesa in stičnih točk telesa s tlemi. Začnite se zavedati okolice v kateri ste. Počasi pričnite premikati glavo in roke. Pri izdihu odprite počasi oči. Kakšno minuto še sedite in počasi vstanite.</p>',

            dodatno: '<ol><li>Vsakič ko se tuširate, čutite kako vse slabe energije tečejo od vas. Vizualizirajte in čutite, kako vse skrbi in neprijetnosti tečejo od vas in v odtok. Ko se stuširate se počutite sveže in lahkotno.</li><li>Med menstruacijo naj se ženske posvetijo sebi in naj počivajo. Naj se umaknejo od vsega kar se le da in se posvetijo sebi</li></ol>'
        }
    }
}
