<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Mala Arkana: Pentakli
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/tarot">Tarot</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Mala Arkana: Pentakli</li>
            </ol>
        </nav>
    </div>
</div>
<div class="container-fluid p-0 bg-header">
    <div class="container p-md-4 p-2">

        <div class="p-md-2 text-black">

            <div class="py-4 text-center">
                <h1 class="text-center">Mala Arkana: Pentakli</h1>
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>

            <p v-html="opis"></p>

            <div class="py-4 text-center">
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>

            <strong>Karte pentaklov so:</strong> <span v-for="karte in pentakli">
                <router-link class="text-decoration-none text-white" :to="`${karte.to}`">{{ karte.ime }}</router-link> |
            </span>

            <div class="py-4 text-center">
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>
            <div class="row row-cols-1 row-cols-xl-4 g-4">
                <div class="col-xl d-flex align-items-center justify-content-center" v-for="(arkana, index) in pentakli"
                     :key="index">
                    <router-link :to="`${arkana.to}`" class="text-decoration-none">
                        <div class="card-my">
                            <div class="content-my">
                                <div class="front">
                                    <img :src="arkana.img" class="img-fluid shadow" :alt="arkana.ime">
                                </div>
                                <div class="back p-2 text-white bg-dark shadow bg-image-cards bg-opacity-75">
                                    <h3 class="text-shadow">{{ arkana.ime }}</h3>
                                    <p>{{ arkana.pregled }}</p>
                                </div>

                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="pb-4 pt-5 text-center">
                <h1>Norčevo potovanje - Kraljestvo Pentakljev</h1>
                <img src="~@/assets/hrline.png" class="img-fluid"><br>
            </div>

            <p v-html="opisPot"></p>
            <strong>Pot Norca: </strong>
            <span v-for="(prikaz, index) in potovanje" :key="index">
            <a class="text-decoration-none text-white" :href="prikaz.ide">{{ prikaz.ime }}</a> |
        </span>

            <div class="py-4 text-center">
                <img src="~@/assets/hrline.png" class="img-fluid text-center">
            </div>
            <div class="row row-cols-1 row-cols-xl-1 g-4">

                <div class="col pt-2" v-for="(pot, index) in potovanje" :key="index" :id="pot.id">
                    <div class="card bg-transparent border-0">
                        <div class="row g-0">
                            <div class="col-lg-3 text-center pb-lg-1 pb-3">
                                <img :src="pot.img" class="img-fluid rounded-start">
                            </div>
                            <div class="col-lg-9 bg-dark shadow-card border-dark rounded-0 bg-image-cards bg-opacity-75 text-white">
                                <h3 class="card-header text-center border-0 bg-transparent">
                                    {{ pot.ime }}
                                </h3>
                                <div class="card-body">
                                    <p class="card-text" v-html="pot.opis"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center d-lg-none">
                        <img src="~@/assets/hrline.png" class="img-fluid text-center pt-3" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
