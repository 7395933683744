<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            Meditacija
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">Meditacija</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot position-relative pb-3">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>Meditacija</h1>
            <p class="lead">"Ne meditiraj... BODI! Ne misli, da si... BODI! Ne razmišljaj o tem, da si... TI SI!"</p>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>

        <div class="container">
            <p>Sem mnenja, da bi se vsak moral naučiti neke vrste meditacije in jo izvajati vsak dan. Čeprav sem bil v
                dilemi ali naj to stran poimenujem meditacija ali dharana, sem se na koncu odločil, da bo nosilo ime
                meditacija. Razlog za to je, ker je vsekakor bolj oprijemljivo ime in pojem dharana vseeno še ni tako
                razvit med zahodnjaki. Kakorkoli, vsaka praksa je dharana oziroma koncentracija, ko pa le ta postane
                popolna pa vodi v stanje meditacije. Zato je zelo pomembno, da kar se da upoštevate podane čase, ki
                določajo koliko časa naj bi minimalno delali na izpopolnjevanju določene prakse, da od nje vzamete kar
                se da veliko.</p>

            <p>Seveda je čas obstanka odvisen od tega, kje trenutno ste in kako dolgo boste rabili, da določeno stvar
                izpopolnite. Lahko, da bo to v krajšem času lahko pa da bo to trajalo 3x dlje. Kakorkoli že, pomembno
                je, da delate na kvaliteti, da svojega uma ne silite v nekaj in da se stvari pojavijo spontano, kot se
                to pojavi stanje meditacije. Um je hkrati most med tem in onim in je hkrati omejitev na poti do višjih
                stanj zavesti. Zato je pomembno, da napredujete počasi, da ste dosledni in da ne obupate.</p>

            <p>Prevečkrat opazim, kako se dan danes ljudje poslužujejo meditacije in upajo na ogromne koristi, hkrati pa
                niso pripravljeni delati, kot je treba. Predlagam, da si vsak dan vzamete čas zase in na določeni
                tehniki ostanete dlje kot pa samo teden ali 14 dni. Naj postane meditacija vaša hrana za dušo, kot je
                hrana hrana vašega telesa. Želim vam prijetno pot in za kakršno koli vprašanje me lahko kontaktirate.</p>
        </div>

        <div class="row row-cols-1 row-cols-xl-3 g-4 pt-3">
            <!-- Meditacija na mirnost telesa in tratak -->
            <div class="col">
                <div class="card bg-dark border-dark rounded-0 shadow-card bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ meditacijaPrva }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text">{{ meditacijaTelo }}</p>
                    </div>
                    <div class="card-footer border-0 bg-transparent">
                        <p class="card-text text-center"><small class="text-white-50">Minimalni čas za to meditacijo:
                            {{ casEna }} meseca</small></p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                            @click="showModalTelo = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- Meditacija na dih in čakre -->
            <div class="col">
                <div class="card bg-dark border-dark rounded-0 shadow-card bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ meditacijaDruga }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text">{{ meditacijaDih }}</p>
                    </div>
                    <div class="card-footer border-0 bg-transparent">
                        <p class="card-text text-center"><small class="text-white-50">Minimalni čas za to meditacijo:
                            {{ casDva }} meseca</small></p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                            @click="showModalDih = true">
                        Obrni
                    </button>
                </div>
            </div>
            <!-- Meditacija na tarot -->
            <div class="col">
                <div class="card bg-dark border-dark rounded-0 shadow-card bg-image-cards bg-opacity-75 text-white h-100">
                    <h3 class="card-header border-0 bg-transparent text-center">{{ meditacijaTretja }}</h3>
                    <img src="~@/assets/hrline.png" class="img-fluid text-center">
                    <div class="card-body">
                        <p class="card-text">{{ meditacijaRojstvo }}</p>
                    </div>
                    <div class="card-footer border-0 bg-transparent">
                        <p class="card-text text-center"><small class="text-white-50">Minimalni čas za to meditacijo:
                            {{ casTri }} meseca</small></p>
                    </div>
                    <button class="btn btn-warning card-footer border-0 rounded-0 bg-tarot fs-5 fw-bold"
                            @click="showModalMeditacija = true">
                        Obrni
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal MEDITACIJE NA TELO: showModalTelo -->
        <vue-final-modal v-model="showModalTelo" classes="modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalTelo = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">{{ meditacijaPrva }}</span>

            <div class="modal__content">
                <div class="container">
                    <h4 class="fw-bold">Meditacija na telo</h4>
                    <p v-html="meditacijaNaTelo"></p>
                    <h4 class="fw-bold">Tratak</h4>
                    <p v-html="tratak"></p>
                    <h4 class="fw-bold">Opomba</h4>
                    <p v-html="opombaEna"></p>
                </div>
            </div>
        </vue-final-modal>

        <!-- Modal MEDITACIJA NA DIH: showModalDih -->
        <vue-final-modal v-model="showModalDih" classes="modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2" @click="showModalDih = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">{{ meditacijaDruga }}</span>

            <div class="modal__content">
                <div class="container">
                    <h4 class="fw-bold">Meditacija na dih</h4>
                    <p v-html="MeditacijaNaDih"></p>
                    <h4 class="fw-bold">Meditacija čakre</h4>
                    <p v-html="chakraShuddhi"></p>
                    <div class="row row-cols-2 row-cols-md-4 text-center">
                        <div class="col">
                            <img :src="muladhara" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="swadhisthana" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="manipura" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="anahata" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="vishuddhi" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="ajna" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="bindu" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                        <div class="col">
                            <img :src="sahasrara" class="img-fluid text-center" style="max-height: 100px" alt="">
                        </div>
                    </div>
                    <h4 class="fw-bold">Opomba</h4>
                    <p v-html="opombaDva"></p>

                </div>
            </div>
        </vue-final-modal>

        <!-- Modal MEDITACIJA PONOVNEGA ROJSTVA: showModalMeditacija -->
        <vue-final-modal v-model="showModalMeditacija"
                         classes="modal-container-three animate__animated animate__bounceInDown"
                         content-class="modal-content">
            <button class="modal__close border-0 rounded-3 bg-dark bg-gradient p-2"
                    @click="showModalMeditacija = false">
                <mdi-close class="fw-bold text-white">X</mdi-close>
            </button>
            <span class="modal__title text-center py-3">{{ meditacijaTretja }}</span>

            <div class="modal__content">
                <div class="container">
                    <p v-html="meditacijaPonovnegaRojstva"></p>
                </div>
            </div>
        </vue-final-modal>

    </div>
</div>
