<div class="container-fluid text-white bg-tarot-index shadow-sm">
    <div class="container p-md-4 p-2">
        <div class="fs-1 fw-bold">
            {{ blogPost.naslov }}
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none" to="/">Domov</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="text-decoration-none " to="/blog">Blog</router-link>
                </li>
                <li class="breadcrumb-item text-white active" aria-current="page">{{ blogPost.naslov }}</li>
            </ol>
        </nav>
    </div>
</div>

<div class="container-fluid p-0 bg-tarot position-relative pb-3">
    <div class="container p-3">
        <div class="py-4 text-center">
            <h1>{{ blogPost.naslov }}</h1>
            <p class="lead">"{{ blogPost.podnaslov }}"</p>
            <img src="~@/assets/hrline.png" class="img-fluid text-center">
        </div>

        <div class="container">
            <div>
                <p v-html="blogPost.vsebina"></p>
            </div>
        </div>

    </div>
</div>
